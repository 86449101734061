import React from "react";
import { Row, Col, Button, Image, Checkbox } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Pizza from "../../../images/pizza1.png";
import Heart from "../../../images/heart.svg";
import LeftArrow from "../../../images/arrow-left.svg";
import AddonSearch from "../../../images/addonSearch.svg";
import AddonCart from "../../../images/addoncart.svg";
import Favorites from "../../../images/Favorites.svg";
import Group8809 from "../../../images/Group8809.svg";

class ComboItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {},
      productPrice: "",
      comboDrinks: [],
      checkedValueFor: false,
      onCheckOfDrinks: "",
      hasModifiers: 0,
      comboDrinksAtts: [],
      drinksForCombo: [],
      drinksComboData: [],
      totaPrice: 0.0,
      drinksIdenti: undefined,
      subProductArray: [],
      modifiedItemsTotal: [],
    };
  }

  componentDidMount = () => { };

  onClickOfAddons = () => {
    const { history } = this.props;

    history.push("../addOnCart/index.js");
  };

  onBackToItems = () => {
    const { history } = this.props;
    history.push("../../thanks");
  };

  onChange = (e) => {
    const {
      comboDrinksAtts,
      drinksComboData,
      comboDrinks,
      product,
      modifiedItemsTotal,
    } = this.state;
    const updatedId = e.target.value.product_id;
    const checkedValueFor = e.target.checked;

    let modifiers = [];
    let modifierFlag = "";
    let drinksIdenti = {};
    let drinkWithModifier = [];
    let listPrice = "";

    let addedDrinkForDisplay = {};
    let drinkIndex = "";
    drinksComboData.map((data) => {
      if (updatedId === data.product_id) {
        this.setState({ checkedValue: true, checkedValueFor: checkedValueFor });
      } else {
        this.setState({
          checkedValue: false,
          checkedValueFor: checkedValueFor,
        });
      }
      return true

    });
    comboDrinksAtts.map((data) => {
      if (updatedId === data.product_id) {
        modifiers = data.atts;
      } return true

    });
    if (checkedValueFor === true) {
      comboDrinks.map((match) => {
        if (updatedId === match.product_id) {
          drinksIdenti = match;

          modifierFlag = drinksIdenti.hasModifiers;
          drinkWithModifier.push(match);
          const index = drinkWithModifier.findIndex(
            (data) => data.product_id === updatedId
          );

          if (drinkWithModifier.length === 1) {
            drinkIndex = modifiedItemsTotal.findIndex(
              (x) => JSON.parse(x.att_name).cn === "No drinks"
            );
            addedDrinkForDisplay.att_name =
              drinkWithModifier[index].product_name;
            addedDrinkForDisplay.att_id = drinkWithModifier[index].product_id;
            addedDrinkForDisplay.attr_sn = drinkWithModifier[index].product_sn;
            addedDrinkForDisplay.selected = true;
            if (drinkIndex > -1) {
              modifiedItemsTotal[drinkIndex] = addedDrinkForDisplay;
            } else {
              const indexDrink = modifiedItemsTotal.length - 1;
              modifiedItemsTotal[indexDrink] = addedDrinkForDisplay;
            }

            product.listPrice = (
              parseFloat(product.Price) +
              parseFloat(drinkWithModifier[index].price)
            ).toFixed(2);

            listPrice = product.listPrice;
          }
        }
        this.setState({ hasModifiers: modifierFlag });
        return true

      });
    } else {
      comboDrinks.map((match) => {
        if (updatedId === match.product_id) {
          product.listPrice = (
            parseFloat(product.listPrice) - parseFloat(match.price)
          ).toFixed(2);

          listPrice = product.listPrice;
          this.setState({ hasModifiers: 0 });
        }
        return true
      });

    }

    this.setState({
      product: product,
      onCheckOfDrinks: updatedId,
      modifiedItemsTotal: modifiedItemsTotal,
      totaPrice: listPrice,
      drinksIdenti: drinksIdenti,
    });
    localStorage.setItem("productForDrinkModifier", JSON.stringify(product));
    localStorage.setItem("selectedDRinkModifier", JSON.stringify(modifiers));
    localStorage.setItem(
      "modifiedItemsTotal",
      JSON.stringify(modifiedItemsTotal)
    );
  };

  drinkModifierPage = () => {
    const { history } = this.props;
    const { product, drinksIdenti } = this.state;
    const drinksForCartAddons = {};

    drinksForCartAddons.att_val_name = drinksIdenti.product_name;
    drinksForCartAddons.att_val_id = drinksIdenti.product_id;
    drinksForCartAddons.att_val_sn = drinksIdenti.product_sn;
    product.selectedAddons.push(drinksForCartAddons);
    localStorage.setItem("productForDrinkModifier", JSON.stringify(product));
    localStorage.setItem("selectedComboDrink", JSON.stringify(drinksIdenti));
    history.push("../drinkModifier/index.js");
  };

  onAddOnCart = () => {
    const { history } = this.props;
    const {
      product,
      drinksIdenti,
      subProductArray,
      defalultAddon,
      modifiedItemsTotal,
    } = this.state;

    const drinksForCartAddons = {};
    const basket = JSON.parse(localStorage.getItem("basket"));
    const firstSubProductObject = {
      Modifiers: product.Modifiers,
      prodNum: `${product.Prod_IDFor}`,
    };

    let subProductObject = {};
    if (
      drinksIdenti === undefined ||
      drinksIdenti === {} ||
      drinksIdenti === null
    ) {
      subProductObject = {
        Modifiers: [],
        prodNum: `${defalultAddon.product_sn}`,
      };
      drinksForCartAddons.att_val_name = defalultAddon.product_name;

      drinksForCartAddons.att_val_id = defalultAddon.product_id;
      drinksForCartAddons.att_val_sn = defalultAddon.product_sn;
    } else {
      subProductObject = {
        Modifiers: [],
        prodNum: `${drinksIdenti.product_sn}`,
      };
      drinksForCartAddons.att_val_name = drinksIdenti.product_name;
      drinksForCartAddons.att_val_id = drinksIdenti.product_id;
      drinksForCartAddons.att_val_sn = drinksIdenti.product_sn;
    }

    subProductArray.push(firstSubProductObject);
    subProductArray.push(subProductObject);
    product.SubProduct = subProductArray;
    product.headerArray = modifiedItemsTotal;
    if (drinksForCartAddons.att_val_sn !== undefined) {
      product.selectedAddons.push(drinksForCartAddons);
    }

    basket.push(product);
    let total = 0.0;

    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }
    }

    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));
    history.push("../../thanks");
  };

  render() {

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveTextTime = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
    const intFrameHeight = window.innerHeight;
    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col {...responsiveDesignForColumn}>
            <img
              src={Pizza}
              alt="Pizza"
              width="100%"
              height="111%"
              style={{ borderRadius: "0px" }}
            />
            <Button
              className="favouriteButton1"
              type="primary"
              shape="circle"
              icon={
                <Image
                  style={{ textAlign: "left" }}
                  width={40}
                  src={LeftArrow}
                />
              }
            />
            <Button
              className="favouriteButton2"
              type="primary"
              shape="circle"
              icon={
                <Image
                  style={{ textAlign: "left" }}
                  width={40}
                  src={AddonSearch}
                />
              }
            />
            <Button
              className="favouriteButton3"
              type="primary"
              shape="circle"
              icon={
                <Image style={{ textAlign: "left" }} width={40} src={Heart} />
              }
            />
            <Button
              className="favouriteButton4"
              type="primary"
              shape="circle"
              icon={
                <Image
                  style={{ textAlign: "left" }}
                  width={40}
                  src={AddonCart}
                />
              }
            />
          </Col>
        </Row>
        <div
          style={{
            backgroundColor: "rgba(28,28,28,0.68)",
            borderRadius: "0px",
            bottom: 0,
            position: "absolute",
            width: "100%",
            height: "85%",
            opacity: "99%",
            zIndex: "500",
          }}
        >
          <Row style={{ textAlign: "center" }}>
            <Col
              {...responsiveText}
              style={{
                padding: "10px",
                paddingBottom: "0px",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "550",
                  marginBottom: "5px",
                }}
              >
                Hut Treat-Meal for 4 Veg @799- (Save Upto 40%)
              </p>
            </Col>
            <Col
              {...responsiveTextTime}
              style={{
                padding: "10px",
                textAlign: "right",
                paddingBottom: "0px",
              }}
            >
              <img
                style={{ textAlign: "center", width: "20%" }}
                alt="group"
                src={Group8809}
              />
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col
              {...responsiveText}
              style={{ padding: "10px", textAlign: "left", paddingTop: "0px" }}
            >
              <p
                style={{
                  color: "#fff",
                  marginBottom: "5px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Rs.525 - Rs.589{" "}
              </p>
            </Col>
            <Col
              {...responsiveTextTime}
              style={{
                padding: "10px",
                textAlign: "right",
                paddingTop: "0px",
                marginTop: "-4%",
              }}
            >
              <Button className="addRatingButton">
                {" "}
                <span
                  style={{
                    color: "#000000",
                    marginBottom: "0px",
                    fontSize: "15px",
                    fontWeight: "500",
                    paddingTop: "4px",
                  }}
                >
                  4.5
                </span>
                <Image
                  style={{ textAlign: "center" }}
                  width={50}
                  src={Favorites}
                />
              </Button>
            </Col>
          </Row>
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "0px",
              bottom: 0,
              position: "absolute",
              width: "100%",
              height: "85%",
              zIndex: "1000",
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "10px",
                  textAlign: "left",
                  paddingBottom: "0px",
                }}
              >
                <p
                  style={{
                    color: "#FFFFFF",
                    marginBottom: "3px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Choose Your 1st Pizza
                </p>
              </Col>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "10px",
                  textAlign: "left",
                  paddingTop: "0px",
                }}
              >
                <p
                  style={{
                    color: "#FFFFFF",
                    marginBottom: "5px",
                    fontSize: "13px",
                    fontWeight: "400",
                    opacity: "80%",
                  }}
                >
                  Margherita Pizza
                </p>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "10px",
                  textAlign: "left",
                  paddingBottom: "0px",
                }}
              >
                <p
                  style={{
                    color: "#FFCA40",
                    marginBottom: "3px",
                    fontSize: "19px",
                    fontWeight: "600",
                  }}
                >
                  CHANGE
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                {...responsiveDesignForColumn}
                style={{
                  padding: "10px",
                  textAlign: "left",
                  paddingBottom: "0px",
                }}
              >
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Choose Your 2nd Pizza
                </p>
              </Col>
            </Row>
            <Row
              {...responsiveDesignForColumn}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
                marginLeft: "0px",
              }}
            >
              <Col {...responsiveDesignForColumn}>
                <div
                  style={{
                    maxHeight: "380px",
                    overflowY: "scroll",
                    paddingBottom: "15px",
                  }}
                >
                  <Form style={{ paddingBottom: "0px" }}>
                    <FormItem
                      style={{
                        backgroundColor: "#000000",
                        borderRadius: "0px",
                        padding: "8px",
                        color: "#fff",
                        marginBottom: "0px",
                      }}
                    >
                      {" "}
                      <Col {...responsiveDesignForItemData}>
                        <b style={{ fontSize: "4.3vw" }}>
                          * Choose Cool drinks
                        </b>
                      </Col>
                    </FormItem>

                    <FormItem>
                      <Col {...responsiveDesignForColumn}>
                        <Checkbox
                          value="1"
                          style={{
                            fontSize: "16px",
                            color: "white",
                            paddingBottom: "8px",
                          }}
                          onChange={this.onChange}
                        >
                          Pepsi Pet Bottle
                          <span style={{ paddingLeft: "15px" }}>+ Rs.30</span>
                        </Checkbox>
                        <Checkbox
                          value="1"
                          style={{
                            fontSize: "16px",
                            color: "white",
                            marginLeft: "0px",
                            paddingBottom: "8px",
                          }}
                          onChange={this.onChange}
                        >
                          Sprite
                          <span style={{ paddingLeft: "15px" }}>+ Rs.30</span>
                        </Checkbox>
                      </Col>
                    </FormItem>
                    <FormItem
                      style={{
                        backgroundColor: "#000000",
                        borderRadius: "0px",
                        padding: "8px",
                        color: "#fff",
                        marginBottom: "0px",
                      }}
                    >
                      {" "}
                      <Col {...responsiveDesignForItemData}>
                        <b style={{ fontSize: "4.3vw" }}>
                          * Choose Cool drinks
                        </b>
                      </Col>
                    </FormItem>

                    <FormItem>
                      <Col {...responsiveDesignForColumn}>
                        <Checkbox
                          value="1"
                          style={{
                            fontSize: "16px",
                            color: "white",
                            paddingBottom: "8px",
                          }}
                          onChange={this.onChange}
                        >
                          Pepsi Pet Bottle
                          <span style={{ paddingLeft: "15px" }}>+ Rs.30</span>
                        </Checkbox>
                        <Checkbox
                          value="1"
                          style={{
                            fontSize: "16px",
                            color: "white",
                            marginLeft: "0px",
                            paddingBottom: "8px",
                          }}
                          onChange={this.onChange}
                        >
                          Sprite
                          <span style={{ paddingLeft: "15px" }}>+ Rs.30</span>
                        </Checkbox>
                      </Col>
                    </FormItem>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default ComboItem;
