import React from "react";
import { Row, Col, Card, Modal, Form, message, Button, Input } from "antd";

import "antd/dist/antd.css";
import cancelImage from "../../../images/cancelImage.png";
import dineIn from "../../../images/DINE-IN.svg";
import takeAway from "../../../images/TAKE-AWAY.svg";
import pickUp from "../../../images/PICK-UP.svg";
import { LeftOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";

class SalesType extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visiblePager: false,
      pagerValue: "",
      pagerInfo: {},
      orderMessages: {},

      pagerAndPickupFlags: {},
      color: "lightgray",
      highLightFlagForDineIn: false,
      highLightFlagForTakeAway: false,
      highLightFlagForPickUp: false,
      saleTypeFlag: false,
      requiredField: [],
      userValues: {},
    };
  }

  componentDidMount = () => {
    const pagerInfo = JSON.parse(localStorage.getItem("pagerInfo"));
    const orderMessages = JSON.parse(localStorage.getItem("imagesForMessages"));
    const userValues = JSON.parse(localStorage.getItem("userValues"));
    const pagerAndPickupFlags = JSON.parse(
      localStorage.getItem("pagerAndPickupFlags")
    );
    this.setState({
      pagerInfo: pagerInfo,
      orderMessages: orderMessages,
      pagerAndPickupFlags: pagerAndPickupFlags,
      userValues: userValues,
    });
  };

  handleCancel = () => {
    this.setState({ visiblePager: false });
  };

  onDineInClick = (id, type) => {
    const { requiredField, userValues } = this.state;
    let userValuesFor = {};
    requiredField.map((data) => {
      userValuesFor.username = requiredField[0].value;
      userValuesFor.number = requiredField[1].value;
      userValuesFor.email = requiredField[2].value;
      return true
    });

    if (requiredField.length === 0) {
      this.setState({ userValues: userValues });
    } else {
      localStorage.setItem("userValues", JSON.stringify(userValuesFor));
      this.setState({ userValues: userValuesFor });
    }

    if (type === "Dine-In") {
      this.setState({
        saleTypeFlag: true,
        highLightFlagForDineIn: true,
        highLightFlagForTakeAway: false,
        highLightFlagForPickUp: false,
      });
    } else {
      this.setState({
        saleTypeFlag: true,
        highLightFlagForTakeAway: true,
        highLightFlagForDineIn: false,
        highLightFlagForPickUp: false,
      });
    }

    const color = "#000000";
    localStorage.setItem("typeForSalesType", JSON.stringify(type));

    localStorage.setItem("colorForSalesType", JSON.stringify(color));
    localStorage.setItem("saleTypeNum", JSON.stringify(id));
  };

  onProceedPay = () => {
    const { history } = this.props;
    const { pagerAndPickupFlags } = this.state;

    if (pagerAndPickupFlags.Pager.Enabled === true) {
      this.setState({ visiblePager: true });
    } else {
      const pagerValue = "";
      localStorage.setItem("userValues", JSON.stringify({}));

      localStorage.setItem("pagerValue", JSON.stringify(pagerValue));
      history.push("../payment/index.js");
    }
  };

  onPickUp = (id, type) => {
    this.setState({
      highLightFlagForPickUp: true,
      highLightFlagForDineIn: false,
      highLightFlagForTakeAway: false,
    });

    const color = "lightgray";
    localStorage.setItem("colorForSalesType", JSON.stringify(color));
    localStorage.setItem("typeForSalesType", JSON.stringify(type));

    localStorage.setItem("saleTypeNum", JSON.stringify(id));
  };

  onMakePayment = (values) => {
    const { history } = this.props;

    localStorage.setItem("userValues", JSON.stringify(values));
    localStorage.setItem("pagerValue", JSON.stringify(""));
    this.setState({ userValues: values });
    history.push("../pickUp/index.js");
  };

  onBackToMenu = () => {
    const { history } = this.props;
    history.push("../insideCart/index.js");
  };

  onSkip = () => {
    this.setState({ visiblePager: false });
    localStorage.setItem("pagerValue", JSON.stringify(""));
    this.setState({ pagerValue: "" });
  };

  onClickOfNum = (value) => {
    const { pagerValue } = this.state;
    let pagerNumber = pagerValue + value;
    if (pagerNumber.length > 3) {
      message.error("Please enter less than 3 digits");
    } else {
      this.setState({ pagerValue: pagerNumber });
    }
  };

  onCancelOfNum = () => {
    const { pagerValue } = this.state;

    let pagerNumber = pagerValue.slice(0, -1);

    this.setState({ pagerValue: pagerNumber });
  };

  onFinalizePager = () => {
    const { pagerValue } = this.state;
    let pagerNumber = pagerValue.slice(0, -3);

    this.setState({ pagerValue: pagerNumber });
  };

  onConfirm = () => {
    const { history } = this.props;
    const { pagerValue } = this.state;
    if (pagerValue.length === 0) {
      message.error("Please enter pager number");
    } else {
      localStorage.setItem("userValues", JSON.stringify({}));
      localStorage.setItem("pagerValue", pagerValue);

      this.setState({ visiblePager: false, pagerValue: "" });
      history.push("../payment/index.js");
    }
  };

  onFieldsChange = (values) => {
    this.setState({ requiredField: values });
  };

  render() {
    const {
      pagerValue,
      pagerInfo,

      pagerAndPickupFlags,
      saleTypeFlag,
      highLightFlagForDineIn,
      highLightFlagForTakeAway,
      highLightFlagForPickUp,
      userValues,
    } = this.state;
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignForColumn = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 24,
      sm: 12,
      md: 12,
    };
    const responsiveDesignDish = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveDesignText = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
    const intViewportWidth = window.innerWidth;
    return (
      <div
        style={{
          heigth: "100%",
          backgroundColor: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          height: "639px",
        }}
      >
        <Row>
          <Col span={24}>
            <Card
              style={{
                fontWeight: "regular",
                fontSize: "25px",
              }}
            >
              <Row
                style={{
                  marginBottom: "-15px",
                  paddingBottom: "3px",
                }}
              >
                <Col
                  {...responsiveDesignForImage}
                  style={{ paddingRight: "3px", marginTop: "-5px" }}
                >
                  <LeftOutlined
                    onClick={this.onBackToMenu}
                    style={{ fontSize: "20px" }}
                  />
                </Col>

                <Col
                  {...responsiveDesignForItemData}
                  style={{
                    textAlign: "center",
                    paddingRight: "35px",
                    paddingTop: "3px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "900",
                      fontSize: "20px",
                      color: "black",
                    }}
                  >
                    Please select order type:
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            {...responsiveDesignForColumn}
            style={{
              paddingLeft: "7px",
              paddingRight: "7px",
              paddingTop: "10px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                position: "relative",
                boxShadow: " 0px 2px 6px -3px",
              }}
              onClick={() =>
                this.onDineInClick(
                  pagerAndPickupFlags.SalesType.DineIn.id,
                  "Dine-In"
                )
              }
            >
              <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                <Col {...responsiveDesignForImage}>
                  <img
                    style={{
                      fontWeight: "700",
                      width: "100%",
                      height: "100%",
                      marginTop: "-9px",
                    }}
                    src={dineIn}
                    alt=""
                  />
                </Col>
                <Col {...responsiveDesignDish} style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "900",
                      color: "black",
                    }}
                  >
                    DINE-IN
                  </p>
                </Col>
                <Col
                  {...responsiveDesignForImage}
                  style={{ textAlign: "center" }}
                >
                  <i
                    className={
                      highLightFlagForDineIn === false
                        ? "fa fa-check-circle fa-2x"
                        : "fa fa-check-circle-selected fa-2x"
                    }
                    aria-hidden="true"
                  ></i>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            {...responsiveDesignForColumn}
            style={{
              paddingLeft: "7px",
              paddingRight: "7px",
              paddingTop: "10px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                position: "relative",
                boxShadow: " 0px 2px 6px -3px",
              }}
              onClick={() =>
                this.onDineInClick(
                  pagerAndPickupFlags.SalesType.Takeaway.id,
                  "Take-Away"
                )
              }
            >
              <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                <Col {...responsiveDesignForImage}>
                  <img
                    style={{
                      fontWeight: "700",
                      width: "100%",
                      height: "100%",
                      marginTop: "-9px",
                    }}
                    src={takeAway}
                    alt=""
                  />
                </Col>
                <Col {...responsiveDesignDish} style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "900",
                      color: "black",
                    }}
                  >
                    TAKE-AWAY
                  </p>
                </Col>
                <Col
                  {...responsiveDesignForImage}
                  style={{ textAlign: "center" }}
                >
                  <i
                    className={
                      highLightFlagForTakeAway === false
                        ? "fa fa-check-circle fa-2x"
                        : "fa fa-check-circle-selected fa-2x"
                    }
                    aria-hidden="true"
                  ></i>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            {...responsiveDesignForColumn}
            style={{
              paddingLeft: "7px",
              paddingRight: "7px",
              paddingTop: "10px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#fff",
                position: "relative",
                boxShadow: " 0px 2px 6px -3px",
              }}
              onClick={() =>
                this.onPickUp(
                  pagerAndPickupFlags.SalesType.PickUp.id,
                  "Pick-Up"
                )
              }
            >
              <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                <Col {...responsiveDesignForImage}>
                  <img
                    style={{
                      fontWeight: "700",
                      width: "100%",
                      height: "100%",
                      marginTop: "-9px",
                    }}
                    src={pickUp}
                    alt=""
                  />
                </Col>
                <Col {...responsiveDesignDish} style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: "900",

                      color: "black",
                    }}
                  >
                    SCHEDULE PICK-UP
                  </p>
                </Col>
                <Col
                  {...responsiveDesignForImage}
                  style={{ textAlign: "center" }}
                >
                  <i
                    className={
                      highLightFlagForPickUp === false
                        ? "fa fa-check-circle fa-2x"
                        : "fa fa-check-circle-selected fa-2x"
                    }
                    aria-hidden="true"
                  ></i>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            bottom: "0",
            width: `${intViewportWidth}px`,
          }}
        >
          <Col
            {...responsiveDesignForColumn}
            style={{
              padding: "7px",
            }}
          >
            {saleTypeFlag === true && highLightFlagForPickUp === false ? (
              <Card
                style={{
                  backgroundColor: "#F35D5D",
                  position: "relative",
                }}
                onClick={this.onProceedPay}
              >
                <Row style={{ marginTop: "0px" }}>
                  <Col
                    {...responsiveDesignForColumn}
                    style={{ textAlign: "center" }}
                  >
                    <b style={{ color: "white", fontSize: "18px" }}>
                      PROCEED TO PAY
                    </b>
                  </Col>
                </Row>
              </Card>
            ) : (
              <div>
                {highLightFlagForPickUp === true ? (
                  <Card
                    style={{
                      display: "none",
                    }}
                  ></Card>
                ) : (
                  <Card
                    style={{
                      backgroundColor: "#d9d9d9",
                      position: "relative",
                    }}
                  >
                    <Row style={{ marginTop: "0px" }}>
                      <Col
                        {...responsiveDesignForColumn}
                        style={{ textAlign: "center" }}
                      >
                        <b style={{ color: "white", fontSize: "18px" }}>
                          PROCEED TO PAY
                        </b>
                      </Col>
                    </Row>
                  </Card>
                )}
              </div>
            )}
          </Col>
        </Row>
        {highLightFlagForPickUp === true ? (
          <div style={{ marginTop: "0px", padding: "9px" }}>
            <Form
              ref={this.formRef}
              name="control-ref"
              initialValues={userValues}
              onFinish={this.onMakePayment}
              onFieldsChange={(_, allFields) => {
                this.onFieldsChange(allFields);
              }}
            // onValuesChange={this.onFormLayoutChange}
            >
              <Form.Item
                label="Name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="number"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                ]}
              >
                <PhoneInput country={"sg"} />
              </Form.Item>
              <Form.Item
                label="E-mail Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail Address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item style={{ paddingTop: "10px" }}>
                <Button
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                    width: "100%",
                    height: "46px",
                    border: "1px solid #F35D5D",
                  }}
                  htmlType="submit"
                >
                  <b style={{ color: "white", fontSize: "18px" }}>
                    SELECT PICK-UP TIME
                  </b>
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div style={{ display: "none" }}></div>
        )}
        <Modal
          title={[
            <Row style={{ textAlign: "center" }}>
              <Col {...responsiveDesignForColumn} style={{ paddingTop: "6px" }}>
                <p
                  style={{
                    color: "black",
                    float: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                    marginBottom: "-1rem",
                  }}
                >
                  {pagerInfo.Message}
                </p>
              </Col>
            </Row>,
          ]}
          visible={this.state.visiblePager}
          onCancel={this.handleCancel}
          footer={false}
          closable={false}
        >
          <Row style={{ textAlign: "center", marginTop: "-20px" }}>
            <Col
              {...responsiveDesignForColumn}
              style={{ paddingBottom: "0px" }}
            >
              <img
                style={{
                  fontWeight: "700",
                  width: "45%",
                  height: "95%",
                }}
                onClick={this.onCancelOfNum}
                src={pagerInfo.Image}
                alt=""
              />
            </Col>
            <Col
              {...responsiveDesignForColumn}
              style={{ paddingBottom: "0px" }}
            >
              <Input
                style={{
                  height: "105%",
                  width: "35%",
                  borderRadius: "5%",
                  fontSize: "30px",
                  color: "black",
                  backgroundColor: "rgb(239, 239, 239)",
                  textAlign: "center",
                  fontWeight: "900",
                }}
                value={pagerValue}
              />
            </Col>
          </Row>
          <br />
          <div
            style={{
              paddingLeft: "15px",
              backgroundColor: "rgb(239, 239, 239)",
              marginLeft: "-23px",
              marginRight: "-23px",
              marginBottom: "-23px",
              paddingTop: "10px",
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col {...responsiveDesignText} style={{ marginLeft: "20px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(1)}
                >
                  1
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(2)}
                >
                  2
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(3)}
                >
                  3
                </button>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center" }}>
              <Col {...responsiveDesignText} style={{ marginLeft: "20px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(4)}
                >
                  4
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(5)}
                >
                  5
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(6)}
                >
                  6
                </button>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center" }}>
              <Col {...responsiveDesignText} style={{ marginLeft: "20px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(7)}
                >
                  7
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(8)}
                >
                  8
                </button>
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="numPadNumbers"
                  onClick={() => this.onClickOfNum(9)}
                >
                  9
                </button>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center" }}>
              <Col {...responsiveDesignText} style={{ marginLeft: "20px" }}>
                <img
                  style={{
                    fontWeight: "700",
                    width: "35%",
                    height: "95%",

                    paddingTop: "15px",
                  }}
                  onClick={this.onCancelOfNum}
                  src={cancelImage}
                  alt=""
                />
              </Col>
              <Col {...responsiveDesignText} style={{ marginLeft: "-30px" }}>
                <button
                  className="zeroNumPadNumbers"
                  onClick={() => this.onClickOfNum(0)}
                >
                  0
                </button>
              </Col>
              <Col
                {...responsiveDesignText}
                style={{
                  marginLeft: "-30px",
                  fontWeight: "700",
                }}
              >
                <button
                  className="zeroNumPadNumbers"
                  onClick={this.onFinalizePager}
                  style={{ fontWeight: "900" }}
                >
                  C
                </button>
              </Col>
            </Row>
            <br />

            <Row
              style={{
                textAlign: "center",
                paddingBottom: "10px",
                backgroundColor: "rgb(239, 239, 239)",
              }}
            >
              <Col
                {...responsiveDesignForColumn}
                style={{ paddingRight: "20px" }}
              >
                <Button
                  style={{
                    backgroundColor: "#F35D5D",
                    position: "relative",
                    width: "60%",
                    height: "100%",
                    border: "1px solid F35D5D",
                  }}
                  onClick={this.onConfirm}
                >
                  <b style={{ color: "white", fontSize: "20px" }}>CONFIRM</b>
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SalesType;
