import React from "react";
import axios from "axios";
import { fnbURL } from "./config";
import { Row, Col, Spin, Button, Form, message, Card } from "antd";
import Background from "./../images/BackgroundImage.svg";
import Login from "./../images/loginImage.png";
import PhoneInput from "react-phone-input-2";
// import { SyncOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import "./sample.css";
import "./../customCss/style.css";

class Sample extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      darkTheme: false,
      onNext: true,
      verifyCustomer: {},
      realDigits: [],
      phone: "91",
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      localStorage.setItem("basket", JSON.stringify([]));
      localStorage.setItem("total", JSON.stringify(0.0));
      localStorage.setItem("itemsQuantity", JSON.stringify(0));
      this.setState({ loading: false });
    }, 500);
  };

  onNext = (values) => {
    this.setState({ loading: true });
    if (values.number !== undefined) {
      let phoneNumber = values.number;
      phoneNumber = phoneNumber.slice(2);
      localStorage.setItem("phoneNumber", JSON.stringify(phoneNumber));
      const getStore = {
        query: `mutation {
          verifyCustomer(contactNo: "${phoneNumber}") {
              otp
              token
              b2cCustomerId
              newCustomer
              firstname
              lastname
              email
          }
      }`,
      };

      axios({
        url: fnbURL,
        method: "POST",
        async: true,
        crossDomain: true,
        data: getStore,
        headers: {
          clientId: "39BC576048054849BFBFEDBF29142A3E",
        },
      }).then((response) => {
        // console.log("response======>>>>>>>>", response);
        if (response.data.data !== null) {
          const verifyCustomer = response.data.data.verifyCustomer;
          if (verifyCustomer !== null) {
            let num = verifyCustomer.otp;
            let digits = num.toString().split("");
            const realDigits = digits.map(Number);
            const clientToken = verifyCustomer.token;
            localStorage.setItem("clientToken", JSON.stringify(clientToken));
            localStorage.setItem(
              "verifyCustomer",
              JSON.stringify(verifyCustomer)
            );
            this.setState({
              onNext: false,
              verifyCustomer: verifyCustomer,
              realDigits: realDigits,
              loading: false,
              clientToken: clientToken,
            });
          } else {
            message.error("Invalid response please check once");
            this.setState({ loading: false });
          }
        } else {
          message.error("Invalid response please check once");
          this.setState({ loading: false });
        }
      });
    }
  };

  onVerifyOTP = (values) => {
    const { verifyCustomer } = this.state;
    const { history } = this.props;
    this.setState({ loading: true });
    if (verifyCustomer.newCustomer === true) {
      setTimeout(() => {
        history.push("/Login");
      }, 500);
    } else {
      const userInfo = {
        email: `${verifyCustomer.email}`,
        firstName: `${verifyCustomer.firstname}`,
        lastName: `${verifyCustomer.lastname}`,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      history.push("../../thanks");
    }
  };

  onFinish = (values) => {
    console.log("Success:", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const { loading, darkTheme, onNext, realDigits } = this.state;
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const antIcon = <LoadingOutlined spin />;
    const intFrameHeight = window.innerHeight;

    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "50vh",
          color: "#BD1906",
        }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            backgroundImage: `url("${Background}")`,
          }}
        >
          <Row>
            <Col
              {...responsiveDesignForColumn}
              style={{ textAlign: "center", padding: "50px" }}
            >
              <img src={Login} alt="Login" />
            </Col>
          </Row>
          {onNext ? (
            <div>
              <Row
                style={{
                  paddingLeft: "25px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <p
                    style={{
                      color: "#292929",
                      fontSize: "22px",
                      fontWeight: "600",
                      marginBottom: "5px",
                    }}
                  >
                    Login
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  paddingLeft: "25px",
                  paddingTop: "5px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <p
                    style={{
                      color: "#292929",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "5px",
                    }}
                  >
                    We need to send OTP to authenticate your number
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "25px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <Form
                    ref={this.formRef}
                    name="control-ref"
                    // onFinish={this.onNext}
                    onFinish={this.onNext}
                    onFinishFailed={this.onFinishFailed}
                  >
                    <Form.Item
                      label="Country"
                      style={{ width: "100%" }}
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Number",
                        },
                      ]}
                    >
                      <PhoneInput
                        country="in"
                        onlyCountries={["in"]}
                        value={this.state.phone}
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </Form.Item>
                    <br />
                    <Row>
                      <Col
                        {...responsiveDesignForColumn}
                        style={{ padding: "45px" }}
                      >
                        <Form.Item>
                          <Button
                            style={{
                              backgroundColor: "#BD1906",
                              borderRadius: "8px",
                              textAlign: "center",
                              color: "#FFFFFF",
                              fontSize: "16px",
                              fontWeight: "600",
                              border: "none",
                              width: "100%",
                              height: "50px",
                            }}
                            htmlType="submit"
                          >
                            NEXT
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <Row
                style={{
                  paddingLeft: "25px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <p
                    style={{
                      color: "#292929",
                      fontSize: "22px",
                      fontWeight: "600",
                      marginBottom: "5px",
                    }}
                  >
                    Verify OTP
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  paddingLeft: "25px",
                  paddingTop: "5px",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <p
                    style={{
                      color: "#292929",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "5px",
                    }}
                  >
                    Please enter the OTP sent to your mobile number
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  paddingTop: "30px",
                  paddingLeft: "25px",
                  paddingRight: "30px",
                }}
              >
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[0]}
                      </p>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[1]}
                      </p>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[2]}
                      </p>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[3]}
                      </p>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[4]}
                      </p>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <p
                        style={{
                          border: "2px solid #d9d9d9",
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      >
                        {realDigits[5]}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center", paddingTop: "30px" }}
                >
                  <p
                    style={{
                      color: "#BBBBBB",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "5px",
                    }}
                  >
                    Didn't receive an OTP ?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <p
                    style={{
                      color: "#BBBBBB",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        color: "#BD1906",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "5px",
                      }}
                    >
                      Resend OTP/
                    </span>
                    Verify via call
                  </p>
                </Col>
              </Row>
              <Row>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ paddingLeft: "50px", paddingRight: "50px" }}
                >
                  <Card
                    style={{
                      backgroundColor: "#BD1906",
                      borderRadius: "8px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: "600",
                      border: "none",
                      width: "100%",
                    }}
                    htmlType="submit"
                    onClick={this.onVerifyOTP}
                  >
                    VERIFY OTP
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Spin>
    );
  }
}

export default Sample;
