import React from "react";
import axios from 'axios'

import { Row, Col, Card, Button, message, Modal, Input, Form,Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import detele from "../../../images/Icon_delete.png";
import LeftArrow from "../../../images/arrow-left.svg";
import LeftArrowWhite from "../../../images/arrow-left.svg";

import shoppingCartWhite from "../../../images/shoppingCartWhite.svg";
import shoppingCart from "../../../images/shopping-cart.svg";
import { fnbURL } from "../../config"
import moment from 'moment'
import "react-phone-input-2/lib/style.css";
import "./index.css";
import '../addOnCart/index.css'
import "antd/dist/antd.css";
import "../../../customCss/style.css";

class InsideCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basket: [],
      product: {},
      total: 0.0,
      itemsValue: 0.0,
      combinationIds: "",
      selectedAddons: [],
      allProducts: [],
      darkTheme: false,
      isaddOn: false,
      mobileNumberVisible: false,
      mobileNumber: '',
      loading:false,

    };
  }

  componentDidMount = () => {
    const basket = JSON.parse(localStorage.getItem("basket"))
    const allProducts = JSON.parse(localStorage.getItem("allProducts"))
    this.setState({ basket: basket, allProducts: allProducts })
    // console.log("fnbURL=============>",fnbURL)
  };

  onProceedPay = () => {
    const { history } = this.props;

    history.push("../salesType/index.js");
  };

  onBackToMenu = () => {
    const { history } = this.props;
    history.push("../../thanks");
  };

  addProductToBasket = (product, Quantity) => {
    const { basket } = this.state;
    const index = basket.findIndex(
      (basketItem) =>
        basketItem.selectedPId === product.selectedPId &&
        parseFloat(basketItem.Quantity) >= 0
    );
    if (index >= 0) {
      basket[index].Quantity = parseFloat(basket[index].Quantity) + Quantity;
      if (basket[index].Quantity <= 0) {
        basket.splice(index, 1);
      }
    } else {
      basket.push(product);
    }
    let total = 0.0;
    let itemsValues = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listprice) {
        total +=
          parseFloat(basket[i].listprice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }

      itemsValues += basket[i].Quantity;
    }

    this.setState({ basket: basket, total: total, itemsValue: itemsValues });

    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsValues);
  };

  deleteProductFromBasket = (selectedProductId) => {
    const { basket } = this.state;
    const index = basket.findIndex(
      (basketItem) => basketItem.Prod_ID === selectedProductId
    );
    if (index >= 0) {
      basket.splice(index, 1);
    }
    let total = 0.0;
    let itemsValues = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      itemsValues += basket[i].Quantity;
    }

    this.setState({ basket: basket, total: total, itemsValue: itemsValues });

    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsValues);
  };

  onCartArrow = () => {
    const { history } = this.props;
    history.push("../../thanks");
  }

  onViewCartClick = () =>{
    const {basket} = this.state
    if(basket.length > 0){
    const subtotalAmount = JSON.parse(localStorage.getItem("total"))
    const mobileNumber = window.localStorage.getItem('phoneNumber')
    const totalAmount = subtotalAmount + 60
    const val = Math.floor(1000 + Math.random() * 9000);
    const date = new Date()
    const formatteddate = moment(date).format('YYYY-MM-DDTHH:mm:ss')
    let name= ''
    let productCode = ''
    let quantity = ''
     let price = ''
     let subTotal = ''
     let subtotalTax = ''
     let total1 = ''
     let totalTax = ''
    const arrayForMutation = []
    const itemLength = basket.length
    window.localStorage.setItem('totalProducts',itemLength)
    for (let index = 0; index < basket.length; index++) {
      const element = basket[index];
      name = basket[index].name
      productCode = basket[index].sku
      quantity= basket[index].Quantity
      price= basket[index].unitprice
      subTotal= basket[index].unitprice
      total1 = basket[index].unitprice
      const selectedAddons1 = basket[index].selectedAddons === undefined || basket[index].selectedAddons === null ? [] : basket[index].selectedAddons
      let addonsArray = []
      selectedAddons1.map(data=>{
        addonsArray.push(
            data
        );
      })
      // console.log("addonsArray================>",addonsArray)
      
      let newArray = []
      for (let index = 0; index < addonsArray.length; index++) {
        const addonname = addonsArray[index].name;
        const id = addonsArray[index].id;
        const price = addonsArray[index].price;
        newArray.push(
          `{
          id :"${id}"
          name:"${addonname}"
          price:"${price}"
        }`
        )
      }
      arrayForMutation.push(
        `{
          name: "${name}"
          productCode: "${productCode}"
          quantity: ${quantity}
          price: ${price}
          subTotal: ${subTotal}
          subtotalTax: 0.00
          total: ${total1}
          totalTax: 0.00
          addOnProducts: [${newArray}]
        }`,
      )
    }
    const getBookinOrder = {
      query: `mutation {
        createOrder(
            order: {
                orderNo: "${val}"
                createdVia: "rest-api"
                storeId: "F2126530FC0141CC92ACCB42301CFABB"
                status: "NEW"
                currency: "INR"
                dateCreated: "${formatteddate}"
                discountTotal: 0.0
                discountTax: 0.0
                cartTax: 0.0
                total: ${totalAmount}
                totalTax: 0
                pricesIncludeTax: "false"
                mobileNo: ${mobileNumber}
                customerIpAddress: ""
                customerNote: ""
                paymentMethod: "razorpay"
                paymentMethodTitle: "Credit Card/Debit Card/NetBanking"
                transactionId: ""
                cartHash: ""
                metaData: [{ key: "_dokan_vendor_id", value: "1" }]
                lineItems: [${arrayForMutation}]
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
        }
    }`,
    }
    axios({
      url: fnbURL,
      method: 'POST',
      data: getBookinOrder,
      headers: {
        clientId: '39BC576048054849BFBFEDBF29142A3E',
      },
    }).then(response => {
      const respose = response.data.data.createOrder
      if(respose.message === "Order created successfully."){
        const razorPayOrderId = response.data.data.createOrder.razorpayOrderId
        const cwcOrderId = response.data.data.createOrder.cwcOrderId
        // do payment for successful orders
        const options = {
          "key": "rzp_test_3sW37wBTmaMDnK", 
          "amount": `${totalAmount * 100}`,
          "currency": "INR",
          "name": "CW Food Ordering",
          "description": "Test Transaction",
          "image": "https://example.com/your_logo",
          "order_id": `${razorPayOrderId}`,
          "prefill": {
              "name": "Gaurav Kumar",
              "email": "gaurav.kumar@example.com",
              "contact": "9999999999"
          },
          "notes": {
              "address": "Razorpay Corporate Office"
          },
        "handler": function (response){
          this.verifyPayment(cwcOrderId,response)
      }.bind(this),
          "theme": {
              "color": "#3399cc"
          }
        };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
          
      }else{
        message.error("Failed to Place Order")
      }
    })
  }else{
    message.error("No Products in cart!")
  }
  }

  verifyPayment = (cwcOrderId,response) =>{
    this.setState({loading:true})
    const verifyPayment = {
      query: `mutation {
        verifyOrderPayment(
            razorPayOrder: {
                cwcOrderId: "${cwcOrderId}"
                razorpayOrderId: "${response.razorpay_order_id}"
                razorpayPaymentId: "${response.razorpay_payment_id}"
                razorpaySignature: "${response.razorpay_signature}"
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
            razorpayPaymentId
            razorpaySignature
        }
    }`,
    }
    axios({
      url: fnbURL,
      method: 'POST',
      data: verifyPayment,
      headers: {
        clientId: '39BC576048054849BFBFEDBF29142A3E',
      },
    }).then(response => {
      // console.log("verify payment response======>",response)
      if(response.data.data.verifyOrderPayment.message === "Payment validated successfully."){
        const { history } = this.props;
        const date = new Date()
        const formatteddate = moment(date).format('YYYY-MM-DDTHH:mm:ss')
        window.localStorage.setItem('cwcOrderId',cwcOrderId)
        window.localStorage.setItem('orderDate',formatteddate)
        history.push("/successQr")
        this.setState({loading:false})
        this.setState({basket:[]})
      }
    })
  }



  render() {
    const { darkTheme, basket, mobileNumberVisible ,loading} = this.state
    const antIcon = <LoadingOutlined spin />;

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveDesignText = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };
    const Quantity = JSON.parse(localStorage.getItem("itemsQuantity"))
    const subtotalAmount = JSON.parse(localStorage.getItem("total"))

    const totalAmount = subtotalAmount + 60

    const intFrameHeight = window.innerHeight;
    const intFrameHeightFor = window.innerHeight / 1.75;
    const intViewportWidth = window.innerWidth;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "40vh",
          color: "#BD1906",
        }}
        size="large"
      >
      <div
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundColor: `${darkTheme === true ? "black" : "white"}`,
          height: `${intFrameHeight}px`,
        }}
        id="example"
      >

        <Card
          style={{
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            border: "0px",
          }}
        >
          <Row
            style={{
              margin: "0px",
              paddingBottom: "3px",
            }}
          >
            <Col
              {...responsiveDesignForImage}

            >
              {darkTheme !== true ? <img
                src={LeftArrow}
                alt="Status"
                style={{ borderRadius: "0px" }}
                onClick={this.onCartArrow}
              /> : (<button
                style={{ borderRadius: "100px", border: "1px solid black" }}

              >
                <img
                  src={LeftArrowWhite}
                  alt="Status"
                  onClick={this.onCartArrow}
                />
              </button>)}
            </Col>
            <Col
              {...responsiveText}
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: `${darkTheme === true ? "#fff" : "#000000"}`,

              }}
            >
              Your Cart
                </Col>
            <Col
              {...responsiveDesignForImage}
              style={{ textAlign: "right" }}
            >
              <img
                src={darkTheme === true ? shoppingCart : shoppingCartWhite}
                alt="shoppingCart"
                style={{
                  width: "18px",
                }}
                onClick={this.onCartClick}
              />
              <button className="notifications" width="20px" style={{ fontSize: "10px" }}>
                {Quantity}
              </button>
            </Col>
          </Row>
        </Card>
        <div
          style={{ maxHeight: `${intFrameHeightFor}px`, overflowY: "scroll" }}
        >
          {basket.map(data => {
            return (<Row>
              <Col {...responsiveDesignForColumn}>
                <Card
                  style={{
                    borderRadius: "1px",
                    backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                    padding: "0px",
                    boxShadow: " 0px 2px 6px -3px",
                    border: "0px",
                  }}
                >
                  <Row >
                    <Col {...responsiveDesignText} value="444">
                      <img
                        src={data.imageUrl}
                        alt=""
                        key="444"
                        width="100px"
                        height="100px"
                        style={{ borderRadius: "5px" }}
                      />
                    </Col>

                    <Col
                      {...responsiveDesignButtons}
                      style={{ textAlign: "left" }}
                    >
                      <b style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                      }}>{data.name}</b>
                      <Row>
                        <Col {...responsiveDesignForColumn}>
                          <p
                            style={{
                              color: "gray",
                              fontSize: "12px",
                              fontWeight: "500",
                              marginBottom: "5px",
                            }}
                          >
                            {data.selectedAddons ? data.selectedAddons.map(value => {
                              return value.name + ","
                            }) : null}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col {...responsiveDesignForColumn}>
                          <p
                            style={{
                              color: `${darkTheme === true ? "#FFCA40" : "#D82215"}`,
                              fontSize: "13px",
                              fontWeight: "550",
                              marginBottom: "5px",
                            }}
                          >
                            Customize{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col {...responsiveDesignForColumn}>
                          <p
                            style={{
                              color: `${darkTheme === true ? "#F2F2F2" : "#000000"}`,
                              fontSize: "12px",
                              fontWeight: "550",
                              marginBottom: "5px",
                            }}
                          >
                            ₹{data.listprice}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      {...responsiveDesignForImage}
                      style={{ textAlign: "right" }}
                    >
                      <img
                        src={detele}
                        alt=""
                        key="444"
                        width="12px"
                        height="15px"
                        style={{ borderRadius: "5px" }}
                      />

                    </Col>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ textAlign: "right", marginTop: "-42px" }}
                    >
                      <Button style={{ padding: "0px", width: "60px" }} className={darkTheme === true ? "addProductButton" : "addQtyButton"} >
                        <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }} onClick={() =>
                          this.addProductToBasket(data, -1)
                        }>-</Button>
                        <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }} >{basket[
                          basket.findIndex(
                            (basketItem) =>
                              basketItem.selectedPId === data.selectedPId &&
                              parseFloat(basketItem.Quantity) >= 0
                          )
                        ].Quantity
                        }</Button>
                        <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }} onClick={
                          () => {
                            this.addProductToBasket(data, 1);
                          }
                        }>+</Button>
                      </Button>
                    </Col>
                  </Row>
                  <div style={{
                    borderBottom: `${darkTheme === true ? "1.5px solid #F2F2F2" : "1.5px solid #E5E5E5"}`, paddingTop: "9px"
                  }} />
                </Card>
              </Col>
            </Row>)
          })}

        </div>
        <br />

        <div>
          <Row
            style={{
              position: "absolute",
              bottom: "0px",
              width: `${intViewportWidth}px`,
              backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            }}
          >
            {" "}
            <Col
              {...responsiveDesignForColumn}
              style={{
                paddingBottom: "9px",
                paddingLeft: "9px",
                paddingRight: "9px",
              }}
            >
              <Card style={{
                backgroundColor: `${darkTheme === true ? "#333333" : "#F2F2F2"}`,
                borderRadius: "3px",
                border: "none"
              }}>
                <Row style={{ margin: "0px" }}>
                  <Col {...responsiveDesignForColumn}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "16px",
                        marginBottom: "10px",
                        borderBottom: `${darkTheme === true ? "1.5px solid #F2F2F2" : "1.5px solid #E5E5E5"}`
                      }}
                    >
                      Bill Details
                    </p>
                  </Col>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <Col {...responsiveDesignButtons}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "14px",
                        marginBottom: "5px",
                        fontWeight: "300"

                      }}
                    >
                      Subtotal
                    </p>
                  </Col>
                  <Col {...responsiveDesignButtons} style={{ textAlign: "right" }}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "14px",
                        marginBottom: "5px",
                        fontWeight: "300"

                      }}
                    >
                      ₹{subtotalAmount}
                    </p>
                  </Col>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <Col {...responsiveDesignButtons}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "14px",
                        marginBottom: "5px",
                        fontWeight: "300"
                      }}
                    >
                      Tax & Fees
                    </p>
                  </Col>
                  <Col {...responsiveDesignButtons} style={{ textAlign: "right" }}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "14px",
                        marginBottom: "5px",
                        fontWeight: "300"

                      }}
                    >
                      + ₹Rs.60
                    </p>
                  </Col>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <Col {...responsiveDesignForItemData}>
                    <p
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      Total
                    </p>
                  </Col>

                  <Col {...responsiveDesignForImage}>
                    <b
                      style={{
                        color: `${darkTheme === true ? "#fff" : "#000000"}`,
                        float: "right",
                        fontSize: "16px",
                      }}
                    >
                      ₹{totalAmount}
                    </b>
                  </Col>
                </Row>

                <Row>
                  <Col {...responsiveDesignForColumn} style={{ paddingTop: "12px", paddingLeft: "12px", paddingRight: "12px" }}>
                    <Card
                      style={{
                        backgroundColor: "#D82215",
                        borderRadius: "8px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "none"

                      }}
                      onClick={() => {
                        this.onViewCartClick();
                      }}
                    >
                      Checkout
                </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          title=""
          visible={mobileNumberVisible}
          onOk={this.getmobileNumber}
          onCancel={this.handleCancel}
          centered
        >
          <Form
            name="control-ref"
            layout="vertical"
            onValuesChange={this.onFormLayoutChange}
          >
            <h6>Mobile Number</h6>
            <Form.Item
              // label=" "
              name="number"
              rules={[
                {
                  required: true,
                  // type: "number",
                  message: "Please input your mobile number!",
                },
              ]}
              style={{ color: "#FFFFFF", width: "100%" }}
            >
              <Input type="number" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      </Spin>
    );
  }
}

export default InsideCart;
