import React from "react";
import axios from "axios";
import { URL, deviceID, App_Key, appSecret, Tenant_Id } from "./config";
import { Row, Col } from "antd";

class Privacy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      privacyPolicies: "",
    };
  }

  componentDidMount = () => {
    this.detailsOfPrivacy();
  };

  detailsOfPrivacy = () => {
    const md5 = require("md5");
    const uri = "api/mobile/getPrivacyPolicy";

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const Request_Body = JSON.stringify({ deviceNo: deviceID });
    const sign = md5(
      App_Key + appSecret + uri + Request_Body + secondsSinceEpoch
    ).toString();

    const data = JSON.stringify({ deviceNo: `${deviceID}` });

    const config = {
      method: "post",
      url: `${URL}`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${Tenant_Id}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${App_Key}`,
        "Serial-Number": `${deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log("response========>>>>>>>>>", response);
        const privacyPolicies = response.data.data;
        this.setState({ privacyPolicies: privacyPolicies });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { privacyPolicies } = this.state;

    return (
      <div
        style={{
          heigth: "100%",
          backgroundColor: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          height: "639px",
        }}
      >
        <Row
          style={{
            paddingTop: "40px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <Col span={24}>
            <b style={{ fontSize: "20px" }}>PRIVACY POLICIES</b>
          </Col>
        </Row>
        <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
          <Row style={{ padding: "40px" }}>
            <Col span={24}>
              <text style={{ fontSize: "15px", whiteSpace: "break-spaces" }}>
                {privacyPolicies}
              </text>
            </Col>
          </Row>
        </div>
        <br />
      </div>
    );
  }
}

export default Privacy;
