import React from "react";
import { Row, Col, Card, message, Spin } from "antd";
import {
  URL,
  deviceID,
  App_Key,
  appSecret,
  Tenant_Id,
  returnUrl,
} from "../../config";
import axios from "axios";
import { SyncOutlined } from "@ant-design/icons";
import masterCard from "../../../images/vias_Master.jpeg";
import paymentArrow from "../../../images/paymentArrow.svg";

import "antd/dist/antd.css";


const Checkout = window.Checkout;

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basket: [],
      paymentFlag: false,
      highLightFlag: false,
      highLightFlagForCounter: false,
      loading: false,
      pagerValue: "",
      color: "lightgray",
      userValues: {},
      payAtCounter: "",
      payByCredit: "",
      amountTotal: "",
      staffDiscount: {},
      discountFlag: false,
      discountMessage: "",
      addCardFlag: false,
    };
  }

  componentDidMount = () => {

  };

  onBackToCart = () => {
    const { history } = this.props;
    const { color } = this.state;
    if (color === "lightgray") {
      history.push("../pickUp/index.js");
    } else {
      history.push("../salesType/index.js");
    }
  };

  onDisplayPayment = () => {
    this.setState({
      highLightFlagForCounter: false,
      paymentFlag: true,
    });
  };

  onPayAtCounter = () => {
    const { color } = this.state;
    if (color === "lightgray") {
      this.setState({
        paymentFlag: false,
        highLightFlag: true,
        highLightFlagForCounter: false,
      });
    } else if (color === "#000000") {
      this.setState({
        paymentFlag: false,
        highLightFlag: true,
        highLightFlagForCounter: true,

        userValues: {},
      });
    }
  };

  onFromPayAtCounter = () => {
    const { history } = this.props;
    const { pagerValue, payAtCounter } = this.state;
    this.setState({ loading: true });
    const basket = JSON.parse(localStorage.getItem("basket"));

    const totalAmount = localStorage.getItem("total");
    const storeId = parseFloat(localStorage.getItem("storeId"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));

    const md5 = require("md5");
    const uri = "api/mobile/syncMobileOrder2";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
      return true
    });

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payAtCounter,
                tender: totalAmount,
                status: "pending",
              },
            ],
          },
          returnUrl: `${returnUrl}`,
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,

          saleTypeNum: saleTypeNum,
          storeId: storeId,
        },
        requestType: 15,
      },
    };

    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      App_Key + appSecret + uri + Request_Body + secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${URL}`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${Tenant_Id}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${App_Key}`,
        "Serial-Number": `${deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    setTimeout(() => {
      axios(config)
        .then((response) => {
          const orderDetails = response.data;

          localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
          history.push("../orderAtCounter/index.js");
        })
        .catch((error) => {
          message.error(`${error}`);
          this.setState({ loading: false });
        });
    }, 1500);
  };

  onPayFromCard = () => {
    const { pagerValue, userValues, color, payByCredit } = this.state;
    this.setState({ loading: true });

    const totalAmount = localStorage.getItem("amountTotal");
    const scheduleDateFor = JSON.parse(localStorage.getItem("scheduleDate"));

    let scheduleDate = "";
    if (scheduleDateFor === null) {
      scheduleDate = "";
    } else {
      scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    }
    const storeId = parseFloat(localStorage.getItem("storeId"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));
    const typeForSalesType = JSON.parse(
      localStorage.getItem("typeForSalesType")
    );
    let userMailId = "";
    let userName = "";
    let userNumber = "";

    if (color === "lightgray") {
      userMailId = userValues.email;
      userName = userValues.username;
      userNumber = userValues.number;
    } else {
      userMailId = "";
      userName = "";
      userNumber = "";
    }

    const basket = JSON.parse(localStorage.getItem("basket"));

    const md5 = require("md5");
    const uri = "api/mobile/syncMobileOrder2";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
      return true
    });

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payByCredit,
                tender: totalAmount,
                status: "pending",
              },
            ],
          },
          returnUrl: `${returnUrl}`,
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,
          ScheduleDate: `${scheduleDate}`,
          saleTypeNum: saleTypeNum,
          storeId: storeId,
          emailTo: `${userMailId}`,
          name: `${userName}`,
          phone: `${userNumber}`,
        },
        requestType: 15,
      },
    };

    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      App_Key + appSecret + uri + Request_Body + secondsSinceEpoch
    ).toString();

    let timeOutError = "";
    const config = {
      method: "post",
      url: `${URL}`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${Tenant_Id}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${App_Key}`,
        "Serial-Number": `${deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };

    setTimeout(() => {
      axios(config)
        .then((response) => {
          const generateSession = response.data.sessionID;
          const cloud_order_number = response.data.cloud_order_number;
          localStorage.setItem(
            "cloud_order_number",
            JSON.stringify(cloud_order_number)
          );
          const orderMessages = JSON.parse(
            localStorage.getItem("storeMessages")
          );
          timeOutError = orderMessages.EFT.Timeout;

          Checkout.configure({
            session: {
              id: `${generateSession}`,
            },
            interaction: {
              merchant: {
                name: `${typeForSalesType}`,
                address: {
                  line1: "200 Sample St",
                },
              },
              displayControl: {
                billingAddress: "HIDE",
              },
            },
          });

          Checkout.showPaymentPage();
        })
        .catch((error) => {
          message.error(`${timeOutError}`);
          this.setState({ loading: false });
        });
    }, 15000);
  };

  discountOpt = (e) => {
    const { discountFlag, staffDiscount } = this.state;
    const { amountTotal } = this.state;
    const totalAmount = localStorage.getItem("total");
    const amountTotal1 = parseFloat(totalAmount);
    const checkedValue = e.target.checked;
    if (discountFlag !== true && checkedValue === true) {
      const discount = JSON.parse(localStorage.getItem("pagerAndPickupFlags"));

      let gst = discount.GSTPercent;
      let gstAmount = amountTotal / gst;
      let totalAmount = amountTotal / gstAmount;

      totalAmount = totalAmount - totalAmount / 10;

      totalAmount = totalAmount * gstAmount;
      const discountMessage = staffDiscount.Message;
      this.setState({
        amountTotal: totalAmount,
        discountFlag: true,
        discountMessage: discountMessage,
      });

      localStorage.setItem("amountTotal", parseFloat(totalAmount).toFixed(2));
    } else if (checkedValue === false) {
      this.setState({
        amountTotal: amountTotal1,
        discountFlag: false,
        discountMessage: "",
      });
      localStorage.setItem("amountTotal", parseFloat(amountTotal1).toFixed(2));
    }
  };

  onAddCard = () => {
    this.setState({ addCardFlag: true });
  };

  render() {
    const {
      loading,
    } = this.state;

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveTextTime = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignDish = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const intFrameHeight = window.innerHeight;
    const intViewportWidth = window.innerWidth;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "#F35D5D" }}
        size="large"
      >
        <div
          style={{
            height: `${intFrameHeight}px`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            paddingLeft: "15px",
            paddingRight: "15px",
            backgroundColor: "#000000",
          }}
        >
          <Row
            style={{
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <Col {...responsiveTextTime} style={{ paddingTop: "5px" }}>
              <img
                src={paymentArrow}
                alt=""
                style={{ borderRadius: "0px", width: "15px", height: "15px" }}
                onClick={this.onDineInClick}
              />
            </Col>
            <Col
              {...responsiveText}
              style={{
                fontSize: "20px",
                color: "#FFFFFF",
                textAlign: "left",
              }}
            >
              Payment Options
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn}>
              <Card>
                <Row>
                  <Col {...responsiveDesignButtons}>
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      Total Amount
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignButtons}
                    style={{ textAlign: "right" }}
                  >
                    <p
                      style={{
                        color: "#D82215",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      Rs.585
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "15px" }}>
              <Card
                style={{
                  backgroundColor: "#333333",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  borderRadius: "5px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "13px",
                        paddingLeft: "10px",
                        paddingTop: "4px",
                        color: "#FFFFFF",
                      }}
                    >
                      Credit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className="fa fa-check-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "8px" }}>
              <Card
                style={{
                  backgroundColor: "#333333",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  borderRadius: "5px",
                  border: "0px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "13px",
                        paddingLeft: "10px",
                        paddingTop: "4px",
                        color: "#FFFFFF",
                      }}
                    >
                      Credit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className="fa fa-check-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "8px" }}>
              <Card
                style={{
                  backgroundColor: "#333333",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  borderRadius: "5px",
                  border: "0px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "13px",
                        paddingLeft: "10px",
                        paddingTop: "4px",
                        color: "#FFFFFF",
                      }}
                    >
                      Credit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className="fa fa-check-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "8px" }}>
              <Card
                style={{
                  backgroundColor: "#333333",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  borderRadius: "5px",
                  border: "0px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "13px",
                        paddingLeft: "10px",
                        paddingTop: "4px",
                        color: "#FFFFFF",
                      }}
                    >
                      Credit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className="fa fa-check-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "8px" }}>
              <Card
                style={{
                  backgroundColor: "#333333",
                  position: "relative",
                  boxShadow: " 0px 2px 6px -3px",
                  borderRadius: "5px",
                  border: "0px",
                }}
                onClick={this.onPayAtCounter}
              >
                <Row style={{ marginBottom: "-8px", paddingTop: "8px" }}>
                  <Col {...responsiveDesignForImage}>
                    <img
                      style={{
                        fontWeight: "700",
                        width: "45px",
                        height: "35px",
                        marginTop: "-5px",
                      }}
                      src={masterCard}
                      alt=""
                    />
                  </Col>
                  <Col {...responsiveDesignDish}>
                    <p
                      style={{
                        fontSize: "13px",
                        paddingLeft: "10px",
                        paddingTop: "4px",
                        color: "#FFFFFF",
                      }}
                    >
                      Credit Card
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForImage}
                    style={{ textAlign: "right" }}
                  >
                    <i
                      className="fa fa-check-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ paddingTop: "15px" }}>
              <p
                style={{
                  fontSize: "14px",
                  paddingTop: "4px",
                  color: "#FFFFFF",
                  fontWeight: "600",
                }}
                onClick={this.onAddCard}
              >
                + Add Card{" "}
              </p>
            </Col>
          </Row>
        </div>
        {this.state.addCardFlag === true ? (
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "10px",
              top: 0,
              position: "absolute",
              width: `${intViewportWidth}px`,
              height: `${intFrameHeight}px`,
              opacity: "99%",
              zIndex: "500",
            }}
          >
            <div
              style={{
                backgroundColor: "#333333",
                borderRadius: "0px",
                bottom: 0,
                position: "absolute",
                width: "100%",
                zIndex: "1000",
              }}
            >
              <Row>
                <Col
                  {...responsiveDesignForColumn}
                  style={{
                    padding: "10px",
                    textAlign: "left",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  <Row>
                    <Col
                      {...responsiveDesignForItemData}
                      style={{
                        paddingLeft: "15px",
                      }}
                    >
                      <p
                        style={{
                          color: "#FFFFFF",
                          fontSize: "18px",
                          fontWeight: "600",
                          marginBottom: "-0.1rem",
                        }}
                      >
                        Add Credit/Debit Card
                      </p>
                    </Col>
                    <Col
                      {...responsiveDesignForImage}
                      style={{ textAlign: "end" }}
                    >
                      <button
                        className="closeCustomization"
                        onClick={this.handleCancel}
                        style={{
                          fontWeight: "500",
                          marginTop: "-5px",
                          backgroundColor: "#333333",
                          color: "#fff",
                          border: "0px",
                        }}
                      >
                        x
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </Spin>
    );
  }
}

export default Payment;
