import React from "react";
import { Row, Col, Card, Button, Spin } from "antd";
import Heart from "../../../images/heart.svg";
import LeftArrow from "../../../images/arrow-left.svg";
import AddonSearch from "../../../images/addonSearch.svg";
import AddonCart from "../../../images/addoncart.svg";
import { LoadingOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";
import "./index.css";

class AddOnCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allProducts: [],
      darkTheme: false,
      restaurant: {},
      basket: []

    };
  }

  componentDidMount = () => {
    this.setState({ loading: true })
    const allProducts = JSON.parse(localStorage.getItem("allProducts"))
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"))
    const restaurantId = localStorage.getItem("restaurantId")
    const index = storeInfo.storeConfig.findIndex((data) => data.storeId === restaurantId);
    const restaurant = storeInfo.storeConfig[index];
    const basket = JSON.parse(localStorage.getItem("basket"))
    this.setState({ loading: false, allProducts: allProducts, restaurant: restaurant, basket: basket })

  };

  onBackToItems = () => {
    const { history } = this.props;
    history.push("../../thanks");
  };

  onChange = (e) => {
    const {
      product,
      modifiedItemsTotal,
      modifiersForChecBox,
      lineAddonsData,
    } = this.state;
    const cartArrayId = e.target.value.att_val_sn;
    const addonObject = e.target.value;
    const addOnCheckedValue = e.target.checked;

    let listPrice = "";

    if (addOnCheckedValue === true) {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex(
          (item) => item.att_val_id === addonObject.att_val_id
        );
        if (itemIndex > -1)
          modifiedItemsTotal[mi].att_val_info[itemIndex].checked = true;
      });

      modifiedItemsTotal.map((data) => {
        data.att_val_info.map((value) => {
          if (addonObject.att_val_id === value.att_val_id) {
            lineAddonsData.push(addonObject);
            // headerId = data.att_id;

            const desc = JSON.parse(addonObject.att_val_name).cn;
            modifiersForChecBox.push({
              description: `${desc}`,
              prodnum: addonObject.att_val_sn,
              quantity: 1,
            });

            const index = lineAddonsData.findIndex(
              (data) => data.att_val_sn === cartArrayId
            );
            product.Price = (
              parseFloat(product.Price) +
              parseFloat(lineAddonsData[index].price)
            ).toFixed(2);
            listPrice = product.Price;
          }
          return true
        });
        return true

      });

      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });

      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        totaPrice: listPrice,
        lineAddonsData: lineAddonsData,
        headerArray: modifiedItemsTotal,
      });
      localStorage.setItem(
        "modifiedItemsTotal",
        JSON.stringify(modifiedItemsTotal)
      );
    } else {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex(
          (item) => item.att_val_id === addonObject.att_val_id
        );
        if (itemIndex > -1)
          delete modifiedItemsTotal[mi].att_val_info[itemIndex].checked;
      });

      const index = lineAddonsData.findIndex(
        (data) => data.att_val_sn === cartArrayId
      );
      const index1 = modifiersForChecBox.findIndex(
        (data) => data.att_val_sn === cartArrayId
      );
      if (index >= 0 || index1 >= 0) {
        product.Price = (
          parseFloat(product.Price) - parseFloat(lineAddonsData[index].price)
        ).toFixed(2);
        modifiersForChecBox.splice(index, 1);
        lineAddonsData.splice(index, 1);
        listPrice = product.Price;
      }
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) modifiedItemsTotal[mi].selected = true;
      });

      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.att_val_info.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });

      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        totaPrice: listPrice,
        lineAddonsData: lineAddonsData,
        headerArray: modifiedItemsTotal,
      });
      localStorage.setItem(
        "modifiedItemsTotal",
        JSON.stringify(modifiedItemsTotal)
      );
    }
  };

  onRadio = (e) => {
    const { product, modifiedItemsTotal } = this.state;
    const defaultRadioddonFlag = true;
    const addOnCheckedValue = e.target.checked;
    const cartArrayId = e.target.value;
    let addOns = [];
    let listPrice = "";
    let modifiersForRadio = [];
    const lineAddonsData = [];
    if (addOnCheckedValue === true) {
      modifiedItemsTotal.map((data) => {
        addOns = data.att_val_info;
        addOns.map((value) => {
          if (cartArrayId === value.att_val_id) {
            lineAddonsData.push(value);
            const desc = JSON.parse(value.att_val_name).cn;
            modifiersForRadio.push({
              description: `${desc}`,
              prodnum: value.att_val_sn,
              quantity: 1,
            });

            if (lineAddonsData.length >= 1) {
              product.Price = (
                parseFloat(product.Price) + parseFloat(value.price)
              ).toFixed(2);

              listPrice = product.Price;
            }
          }
          return true;
        });
        return true;
      });
    }

    this.setState({
      cartArrayId: cartArrayId,
      modifiersForRadio: modifiersForRadio,
      totaPrice: listPrice,
      product: product,
      lineAddonsData: lineAddonsData,
      defaultRadio: 0,
      defaultRadioddonFlag: defaultRadioddonFlag,
    });
  };

  onAddOnCart = () => {
    const { history } = this.props;

    const {
      product,
      modifiersForChecBox,
      modifiersForRadio,
      totaPrice,
      lineAddonsData,
      headerArray,
    } = this.state;
    const basket = JSON.parse(localStorage.getItem("basket"));
    let Modifiers = [];

    modifiersForRadio.map((data) => {
      Modifiers.push(data);
      return true

    });
    modifiersForChecBox.map((data) => {
      Modifiers.push(data);
      return true

    });
    product.Modifiers = Modifiers;
    product.Quantity = 1;
    product.listPrice = totaPrice;
    product.selectedAddons = lineAddonsData;
    product.headerArray = headerArray;

    basket.push(product);

    let total = 0.0;

    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }
    }
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));

    history.push("../../thanks");
  };

  onClickOfContinue = () => {
    const { history } = this.props;
    const {
      product,
      comboSno,
      modifiersForChecBox,
      totaPrice,
      modifiersForRadio,
      lineAddonsData,
      defalultAddon,
      headerArray,
    } = this.state;
    let Modifiers = [];

    if (modifiersForRadio.length === 0 && defalultAddon !== undefined) {
      lineAddonsData.push(defalultAddon);
      const desc = JSON.parse(defalultAddon.att_val_name).cn;
      modifiersForRadio.push({
        description: `${desc}`,
        prodnum: defalultAddon.att_val_sn,
        quantity: 1,
      });
    }

    modifiersForRadio.map((data) => {
      Modifiers.push(data);
      return true

    });
    modifiersForChecBox.map((data) => {
      Modifiers.push(data);
      return true

    });
    product.Modifiers = Modifiers;
    product.Quantity = 1;
    product.listPrice = totaPrice;
    product.comboSno = comboSno;
    product.selectedAddons = lineAddonsData;
    product.headerArray = headerArray;
    localStorage.setItem("productForBasket", JSON.stringify(product));
    history.push("../comboItems/index.js");
  };

  onItem = () => {
    const { history } = this.props;
    history.push("../additionalCart/index.js");
  };

  onAddProduct = () => {
    const { history } = this.props;
    history.push("./viewCart/comboItems/index.js");
  };

  addProductToBasket = (product, QuantityFor) => {
    const { basket } = this.state;
    if (product.add_on_group && QuantityFor > 0) {
      this.setState({ loading: true })
      const addOnLength = product.add_on_group.length
      if (addOnLength > 0) {
        localStorage.setItem("selectedProductId", product.product_id);
        const { history } = this.props;
        setTimeout(() => {
          this.setState({ loading: false })
          history.push("../additionalCart/index.js");
        }, 1000);

      }
    } else {
      const index = basket.findIndex(
        (basketItem) =>
          basketItem.product_id === product.product_id &&
          parseFloat(basketItem.Quantity) >= 0
      );

      if (index >= 0) {
        basket[index].Quantity = basket[index].Quantity + QuantityFor;
        if (basket[index].Quantity <= 0) {
          basket.splice(index, 1);
        }
      } else {
        product.Quantity = QuantityFor;
        basket.push(product);
      }

      let total = 0.0;
      let itemsQuantity = 0.0;
      for (let i = 0; i < basket.length; i += 1) {
        total += parseFloat(basket[i].listprice).toFixed(2) * basket[i].Quantity;
        itemsQuantity += basket[i].Quantity;
      }
      this.setState({ basket: basket, total: total, isaddOn: true });

      localStorage.setItem("basket", JSON.stringify(basket));
      localStorage.setItem("total", total);
      localStorage.setItem("itemsQuantity", itemsQuantity);
    }
  };

  onProductsArrow = () => {
    const { history } = this.props;
    history.push("../../thanks");
  };

  onCartClick = () => {
    const { history } = this.props;
    history.push("../insideCart/index.js");
  }

  render() {
    const { loading,
      allProducts, darkTheme, basket, restaurant } = this.state
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };
    const responsiveDesignForSkip = {
      xxl: 18,
      xl: 18,
      lg: 18,
      xs: 18,
      sm: 18,
      md: 18,
    };
    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };
    const responsiveTextTime = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignDish = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };
    const intFrameHeight = window.innerHeight;
    const antIcon = <LoadingOutlined spin />;
    const Quantity = JSON.parse(localStorage.getItem("itemsQuantity"))

    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "40vh",
          color: "#BD1906",
        }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
          }}
        >
          <Row>
            <Col {...responsiveDesignForColumn}>
              <img
                src={restaurant.storeImage}
                alt="product"
                style={{ borderRadius: "0px", width: "100%", height: "100%" }}
              />
              <Row style={{ padding: "15px", position: "absolute", width: "100%", top: 0 }}>
                <Col {...responsiveTextTime}>
                  <button
                    style={{ borderRadius: "100px", border: "0px solid black", height: "25px", backgroundColor: "#fff" }}
                  >
                    <img
                      src={LeftArrow}
                      alt="Status"
                      onClick={this.onProductsArrow}
                    />
                  </button>
                </Col>
                <Col {...responsiveText} style={{ textAlign: "right" }}>
                  <button
                    style={{ borderRadius: "100px", border: "0px solid black", marginRight: "10px", height: "25px", backgroundColor: "#fff" }}
                  > <img
                      style={{ textAlign: "left" }}
                      alt="search"
                      src={AddonSearch}
                    /></button>
                  <button
                    style={{ borderRadius: "100px", border: "0px solid black", marginRight: "10px", height: "25px", backgroundColor: "#fff" }}
                  > <img
                      style={{ textAlign: "left" }}
                      alt="search"
                      src={Heart}
                    /></button>
                  <button
                    style={{ borderRadius: "100px", border: "0px solid black", height: "25px", backgroundColor: "#fff" }}
                  > <img
                      style={{ textAlign: "left" }}
                      alt="search"
                      src={AddonCart}
                      onClick={this.onCartClick}

                    /> <button className="addNotifications" width="20px">
                      {Quantity}
                    </button></button>
                </Col>
              </Row>
            </Col>

          </Row>

          <Row>
            <Col {...responsiveDesignForColumn}>
              <Card
                style={{
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  border: "0px",
                }}
              >
                <Row style={{ marginBottom: "-22px" }}>
                  <Col
                    {...responsiveDesignForItemData}
                    style={{ marginBottom: "-7px" }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      {restaurant.name}
                    </p>
                  </Col>

                  <Col {...responsiveText}>
                    <p
                      style={{
                        color: "#8D8D8D",
                        fontSize: "12px",
                      }}
                    >
                      {restaurant.shortDescription}
                    </p>
                  </Col>
                  <Col {...responsiveTextTime} style={{ textAlign: "end" }}>
                    <p
                      style={{
                        color: "#8D8D8D",
                        fontSize: "12px",
                      }}
                    >
                      Closes at 07:00 PM
                  </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn}>
              <Card
                style={{
                  border: "0px",
                  backgroundColor: "#000000AD",
                }}
              >
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: "10px",
                    marginBottom: "1px",
                  }}
                >
                  Block- A, Ground Floor, Mind Space
              </p>
              </Card>
            </Col>
          </Row>
          <div
            style={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
              backgroundColor: `${darkTheme === true ? "black" : "white"}`,
              height: "100%",
              width: "100%",
              paddingTop: "8px",

            }}
          >

            <Row style={{ paddingLeft: "10px" }}>
              <Col {...responsiveTextTime} >
                <Button
                  style={{
                    backgroundColor: "#FFCA40",
                    width: "auto",
                    height: "35px",
                    borderRadius: "5px",
                    border: "0px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  What's New
                  </Button>
              </Col>
              <Col {...responsiveTextTime}>
                <Button
                  style={{
                    backgroundColor: "#000000",
                    width: "auto",
                    height: "35px",
                    borderRadius: "5px",
                    border: "2px solid #F2F2F2",
                    color: "#FFFFFF",
                    fontWeight: "500",
                  }}
                >
                  Best Sellers
                  </Button>
              </Col>
              <Col {...responsiveTextTime}>
                <Button
                  style={{
                    backgroundColor: "#000000",
                    width: "auto",
                    height: "35px",
                    borderRadius: "5px",
                    border: "2px solid #F2F2F2",
                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                >
                  Categories
                  </Button>
              </Col>
            </Row>

            {allProducts.map(data => {
              return (
                <div
                  style={{
                    position: "relative",
                    backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                    padding: "10px",
                    borderBottom: "1px solid #F2F2F2",
                  }}
                >
                  <img
                    src={data.imageUrl}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                    }}
                  />

                  <Card
                    style={{
                      border: "0px",
                      backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                      margin: "-10px",
                    }}
                  >
                    <Row style={{ borderBottom: "2px solid #F2F2F2" }}>
                      <Col
                        {...responsiveDesignForSkip}
                        style={{ marginBottom: "-7px" }}
                      >
                        <p
                          style={{
                            color: `${darkTheme === true ? "white" : "black"}`,
                            fontSize: "18px",
                            paddingTop: "3px",
                            fontWeight: "600",
                          }}
                        >
                          {data.name}
                        </p>
                      </Col>
                      {basket.findIndex(
                        (basketItem) =>
                          basketItem.product_id === data.product_id &&
                          parseFloat(basketItem.Quantity) >= 0
                      ) >= 0 ? (<Col {...responsiveDesignDish} style={{ textAlign: "end" }}>
                        <Button style={{ width: "60px", padding: "0px" }} className={darkTheme === true ? "addProductButton" : "addQtyButton"}>
                          <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }} onClick={() =>
                            this.addProductToBasket(data, -1)
                          }>-</Button>
                          <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }}>{
                            basket[
                              basket.findIndex(
                                (basketItem) =>
                                  basketItem.product_id === data.product_id &&
                                  parseFloat(basketItem.Quantity) >= 0
                              )
                            ].Quantity
                          }</Button>
                          <Button className={darkTheme === true ? "addProduct" : "addProductQty"} style={{ padding: "5px" }} onClick={
                            () => {
                              this.addProductToBasket(data, 1);
                            }
                          }>+</Button>
                        </Button>
                        <Col
                          {...responsiveDesignForColumn}
                          style={{ textAlign: "right" }}
                        >
                          <p
                            style={{
                              color: `${darkTheme === true ? "#FFFFFF" : "#8D8D8D"}`,
                              fontSize: "10px",
                            }}
                          >
                            {data.add_on_group !== null ? "Customizable" : ""}
                          </p>
                        </Col>
                      </Col>) : (
                        <Col {...responsiveDesignDish} style={{ textAlign: "end" }}>

                          <Button className={darkTheme === true ? "insideCart" : "insideCartQty"} onClick={
                            () => {
                              this.addProductToBasket(data, 1)
                            }
                          }>{data.add_on_group !== null ? "Add +" : "Add"}</Button>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ textAlign: "right" }}
                          >
                            <p
                              style={{
                                color: `${darkTheme === true ? "#FFFFFF" : "#8D8D8D"}`,
                                fontSize: "10px",
                              }}
                            >
                              {data.add_on_group !== null ? "Customizable" : ""}
                            </p>
                          </Col>
                        </Col>)

                      }
                      <Col {...responsiveText}>
                        <p
                          style={{
                            color: `${darkTheme === true ? "#F2F2F2" : "#000000"}`,
                            fontSize: "14px",
                            fontWeight: "550",
                          }}
                        >
                          ₹{data.listprice}
                        </p>
                      </Col>
                      <Col {...responsiveText}>

                        <p style={{
                          color: `${darkTheme === true ? "#F2F2F2" : "#8D8D8D"}`,
                          fontSize: "12px",
                          marginTop: "-10px",
                        }}>{data.short_desc}</p>
                      </Col>
                    </Row>
                  </Card>
                </div>
              )
            })}
          </div>
        </div>
      </Spin>
    );
  }
}

export default AddOnCart;
