import React from "react";
import {
  Row,
  Col,
  Card,
  Spin,
  Button,
  message,
} from "antd";
import {
  URL,
  deviceID,
  App_Key,
  appSecret,
  Tenant_Id,

} from "../../config";
import LeftArrow from "../../../images/arrow-left.svg";
import LeftArrowWhite from "../../../images/arrow-left.svg";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import { fnbURL } from "../../config";
import moment from 'moment'


import "antd/dist/antd.css";

class OrderPlacing extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      loading: false,
      orderNumber: "",
      orderStatus: "",
      imageForSuccess: "",
      basket: [],
      totalAmount: 0.0,
      receiptFlag: false,
      values: {},
      colorForSalesType: "",
      scheduleDate: "",
      storeName: "",
      streetName: "",
      payByCredit: "",
      darkTheme: false,
      orderHistoryData: []
    };
  }

  componentDidMount = () => {
    this.getOrdersHistory()
  };

  onReceipt = (values) => {
    const { payByCredit } = this.state;
    this.setState({ receiptFlag: true, values: values });
    const basket = JSON.parse(localStorage.getItem("basket"));
    const totalAmount = parseFloat(localStorage.getItem("total")).toFixed(2);
    const pagerValue = JSON.parse(localStorage.getItem("pagerValue"));
    const scheduleDate = JSON.parse(localStorage.getItem("scheduleDate"));
    const saleTypeNum = parseFloat(localStorage.getItem("saleTypeNum"));
    const storeId = parseFloat(localStorage.getItem("storeId"));

    const cloud_order_number = JSON.parse(
      localStorage.getItem("cloud_order_number")
    );
    const userMailId = values.email;

    const userName = values.username;
    const md5 = require("md5");
    const uri = "api/mobile/sendEmailReceipt";

    const sendingItems = [];
    const asyncBasket = [];

    basket.map((data) => {
      if (data.comboSno && data.SubProduct) {
        asyncBasket.push({
          MainProduct: `${data.comboSno}`,
          SubProducts: {
            SubProduct: data.SubProduct,
          },
        });
      } else {
        if (data.Modifiers) {
          sendingItems.push({
            Modifiers: data.Modifiers,
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        } else {
          sendingItems.push({
            Modifiers: [],
            prodNum: `${data.Prod_IDFor}`,
            quantity: data.Quantity,
          });
        }
      }
      return true
    });

    const obj_PixelPoint_OrderBefore = {
      Request: {
        Transaction: {
          ComboItems: {
            ComboItem: asyncBasket,
          },
          Payments: {
            Payment: [
              {
                methodNumber: payByCredit,
                tender: totalAmount,
                status: "paid",
              },
            ],
          },
          SingleItems: {
            SingleItem: sendingItems,
          },
          label: pagerValue,
          ScheduleDate: `${scheduleDate}`,
          saleTypeNum: saleTypeNum,
          storeId: storeId,
          emailTo: `${userMailId}`,
          name: `${userName}`,
          phone: "",
          SaaS_order_number: `${cloud_order_number}`,
        },
        requestType: 15,
      },
    };

    const Request_Body = JSON.stringify(obj_PixelPoint_OrderBefore);

    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);

    const sign = md5(
      App_Key + appSecret + uri + Request_Body + secondsSinceEpoch
    ).toString();

    const config = {
      method: "post",
      url: `${URL}`.concat(`${uri}`),
      headers: {
        "Content-Type": "application/json",
        "Tenant-Id": `${Tenant_Id}`,
        time: `${secondsSinceEpoch}`,
        sign: `${sign}`,
        appkey: `${App_Key}`,
        "Serial-Number": `${deviceID}`,
        "Sale-Channel": "APP",
        "Update-Channel": "APP",
      },
      data: Request_Body,
    };
    axios(config)
      .then((response) => { })
      .catch((error) => {
        message.error(`${error}`);
      });
  };

  onGetBackToHome = () => {
    localStorage.setItem("basketQunatityItems", JSON.stringify([]));
    localStorage.setItem("basket", JSON.stringify([]));
    localStorage.setItem("total", 0);
    localStorage.setItem("itemsQuantity", 0);
    localStorage.setItem("placingDetails", JSON.stringify([]));
    localStorage.setItem("allProductsData", JSON.stringify([]));
    localStorage.setItem("orderDetails", JSON.stringify({}));
    localStorage.setItem("userValues", JSON.stringify({}));
    localStorage.setItem("pagerValue", JSON.stringify(""));
    localStorage.setItem("resultIndicator1", JSON.stringify(""));
    localStorage.setItem("successIndicator", JSON.stringify([]));
    localStorage.setItem("scheduleDate", JSON.stringify(""));
    localStorage.removeItem("Category_Id");

  };

  feedBack = () => {
    const { history } = this.props;
    history.push("../../feedBack");
  }

  getOrdersHistory = () => {
    this.setState({ loading: true })
    const data = JSON.parse(window.localStorage.getItem('verifyCustomer'))
    const customerId = data.b2cCustomerId
    const getProduct = {
      query: `query {
        getMyOrders(customerId: "${customerId}") {
            cWCOrderId
            orderNo
            dateCreated
            status
            paymentMethod
            storeName
            grossAmount
            lineItems
                {
                    productCode
                    name
                    quantity
                    price
                    total
                    addOnProducts{
                        id
                        name
                        price
                    }
                }
              }
    }`
    };
    axios({
      url: fnbURL,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getProduct,
      headers: {
        clientId: '39BC576048054849BFBFEDBF29142A3E',
      },

    }).then(response => {
      const responseData = response.data.data.getMyOrders
      this.setState({ orderHistoryData: responseData,loading:false })
    })
  }

  getBarcode = (data) => {
    const date1= moment(data.dateCreated).format('YYYY-MM-DDTHH:mm:ss')
    const total = data.grossAmount
    const newtotal = total > 0 ? (total - 60) : total
    window.localStorage.setItem('total', newtotal)
    window.localStorage.setItem('totalProducts', data.lineItems.length)
    window.localStorage.setItem('cwcOrderId', data.cWCOrderId)
    window.localStorage.setItem('orderDate',date1)
    const { history } = this.props
    history.push("/successQr")
  }

  render() {
    const {
      loading,
      darkTheme,
      orderHistoryData
    } = this.state;
    // console.log("orderHistoryData==================>",orderHistoryData)
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
    const responsiveDesignForSkip = {
      xxl: 18,
      xl: 18,
      lg: 18,
      xs: 18,
      sm: 18,
      md: 18,
    };
    const responsiveDesignForQuantity = {
      xxl: 2,
      xl: 2,
      lg: 2,
      xs: 2,
      sm: 2,
      md: 2,
    };
    const responsiveDesignForItemData = {
      xxl: 6,
      xl: 6,
      lg: 6,
      xs: 6,
      sm: 6,
      md: 6,
    };
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };


    const responsiveDesignButtons = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };
    const intFrameHeight = window.innerHeight;
    const antIcon = <SyncOutlined spin />;
    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{ position: "relative", top: "25em", color: "#BD1906" }}
        size="large"
      >
        <div
          style={{
            height: `${intFrameHeight}px`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            paddingLeft: "15px",
            paddingRight: "15px",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            overflow: 'scroll'
          }}
        >
          <Card
            style={{
              backgroundColor: `${darkTheme === true ? "black" : "white"}`,
              border: "0px",
            }}
          >
            <Row
              style={{
                margin: "0px",
                paddingBottom: "3px",
              }}
            >
              <Col
                {...responsiveDesignForImage}
                style={{ paddingTop: "5px" }}
                onClick={this.onCartArrow}
              >
                {darkTheme !== true ? <img
                  src={LeftArrow}
                  alt="Status"
                  style={{ borderRadius: "0px" }}
                  onClick={this.onCartArrow}
                /> : (<button
                  style={{ borderRadius: "100px", border: "1px solid black" }}
                >
                  <img
                    src={LeftArrowWhite}
                    alt="Status"

                  />
                </button>)}
              </Col>
              <Col
                {...responsiveText}
                style={{
                  fontSize: "20px",
                  color: `${darkTheme === true ? "white" : "#000000"}`,
                  textAlign: "left",
                }}
              >
                Order History{" "}
              </Col>
            </Row>
          </Card>

          <div>
            {orderHistoryData.map(data =>
              <Card
                style={{
                  backgroundColor: `${darkTheme === true ? "#333333" : "#F2F2F2"}`,
                  border: "0px",
                  borderRadius: "6px",
                  marginBottom: '15px'
                }}
              >
                <Row style={{ marginBottom: "-8px" }}>
                  <Col {...responsiveDesignForSkip}>
                    <b style={{
                      color: `${darkTheme === true ? "white" : "#000000"}`,
                      fontSize: "13px"
                    }}>
                      {data.storeName}
                    </b>
                  </Col>
                  <Col
                    {...responsiveDesignForItemData}
                    style={{ textAlign: "right" }}
                  >
                    <p
                      style={{
                        color: `${darkTheme === true ? "white" : "#000000"}`,

                        fontSize: "11px",
                        marginBottom: "0px",
                      }}
                    >
                      Panjagutta
                 </p>
                  </Col>

                  <Col {...responsiveDesignForSkip}>
                    <b style={{ color: "#8D8D8D", fontSize: "11px" }}>
                      {/* July 29 2021, 10:28 AM */}
                      {data.dateCreated}
                    </b>
                  </Col>
                  <Row style={{ paddingTop: "8px" }}>
                    <Col {...responsiveDesignForSkip}  onClick={() => this.getBarcode(data)} >
                      <b style={{
                        color: `${darkTheme === true ? "white" : "#000000"}`,
                        fontSize: "13px"
                      }}>
                        {/* Hut Treat-Meal for 4 Veg @799- (Save Upto 40%) */}
                        {data.lineItems.length > 0 ? data.lineItems[0].name : ''}
                      </b>
                    </Col>
                    <Col {...responsiveDesignForQuantity}>
                      <b style={{
                        color: `${darkTheme === true ? "white" : "#000000"}`,
                        fontSize: "13px"
                      }}>{data.lineItems.length > 0 ? "X" + data.lineItems[0].quantity : ''} </b>
                    </Col>
                    <Col {...responsiveDesignForImage}>
                      <b style={{
                        float: "right", color: `${darkTheme === true ? "white" : "#000000"}`,
                      }}>₹{data.grossAmount}</b>
                    </Col>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ paddingTop: "8px" }}
                    >
                      <ul style={{ marginLeft: "-25px" }}>
                        <li
                          style={{
                            color: "#8D8D8D",
                            opacity: "80%",
                            fontSize: "11px",
                          }}
                        >
                          Margherita - Pan , Corn & Cheese - Pan Pepsi Pet Bottle{" "}
                        </li>
                      </ul>
                    </Col>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ paddingTop: "8px" }}
                    >
                      <Row>
                        <Col {...responsiveDesignButtons}>
                          <Button
                            style={{
                              backgroundColor: "#fff",
                              width: "140px",
                              height: "40px",
                              color: "#000000",
                              border: "1px solid #000000",
                              borderRadius: "3px",
                            }}
                          >
                            REORDER
                       </Button>
                        </Col>
                        <Col {...responsiveDesignButtons} style={{ textAlign: "right" }}>
                          <Button
                            style={{
                              backgroundColor: "#fff",
                              width: "140px",
                              height: "40px",
                              color: "#000000",
                              border: "1px solid #000000",
                              borderRadius: "3px",
                            }}
                            onClick={this.feedBack}
                          >
                            RATE ORDER
                       </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </Card>
            )}
          </div>
        </div>
      </Spin>
    );
  }
}

export default OrderPlacing;
