const initialState = {
  selectedCategoryData: [],
  selectedCategoryId: {},
  orderDetailsData: {},
  selectedAddonsData: [],
  drinkModifierData: [],
  comboFirstProduct: [],
};

export default function CategoryView(state = initialState, action) {
  if (action.type === "SELECTED_CATGORY_DATA") {
    return {
      ...state,
      selectedCategoryData: action.payload,
    };
  }
  if (action.type === "SELECTED_CAT_ID") {
    return {
      ...state,
      selectedCategoryId: action.payload,
    };
  }
  if (action.type === "ORDER_DETAILS_DATA") {
    return {
      ...state,
      orderDetailsData: action.payload,
    };
  }
  if (action.type === "SELECTED_ADDONS_ARRAY") {
    return {
      ...state,
      selectedAddonsData: action.payload,
    };
  }
  if (action.type === "SELECTED_dRINK_MODIFIERS") {
    return {
      ...state,
      drinkModifierData: action.payload,
    };
  }
  if (action.type === "COMBO_fIRST_PRODUCT") {
    return {
      ...state,
      comboFirstProduct: action.payload,
    };
  }
  return state;
}
