export const URL = "https://mmclabapi.poscorp.sg/";
export const deviceID = "78538822160896638373";
export const App_Key = "sst";
export const appSecret = "sst";
export const Tenant_Id = "1010";
export const eventId = "2003";

// local url
/* export const returnUrl =
  "http://localhost:3000/#/viewCart/orderPlacing/index.js";
 */
// live url

export const returnUrl =
  "https://h5ordering.mycw.in/#/viewCart/orderPlacing/index.js";


// cw food order url's

export const fnbURL =
  "https://sapp.mycw.in/fnbordering/graphql";
