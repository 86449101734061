import React from "react";
import { Row, Col, Button, Card, Checkbox, Spin } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Heart from "../../../images/heart.svg";
import LeftArrow from "../../../images/arrow-left.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';

import AddonSearch from "../../../images/addonSearch.svg";
import AddonCart from "../../../images/addoncart.svg";
import Favorites from "../../../images/Favorites.svg";
import Group8809 from "../../../images/Group8809.svg";

import "antd/dist/antd.css";

class AdditionalCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      darkTheme: false,
      product: {},
      productPrice: 0.0,
      totaPrice: 0.0,
      modifiedItemsTotal: [],
      modifiersForChecBox: [],
      lineAddonsData: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    const productId = localStorage.getItem("selectedProductId")
    const allProducts = JSON.parse(localStorage.getItem("allProducts"))
    const index = allProducts.findIndex((data) => data.product_id === productId);
    const product = allProducts[index];
    this.setState({
      loading: false, product: product,
      productPrice: product.listprice,
      modifiedItemsTotal: product.add_on_group
    })
  };

  onProductsArrow = () => {
    const { history } = this.props;
    history.push("../addOnCart/index.js");
  };

  onChange = (e) => {
    const {
      product,
      modifiedItemsTotal,
      modifiersForChecBox,
      lineAddonsData,
    } = this.state;
    const cartArrayId = e.target.value.id;
    const addonObject = e.target.value;
    const addOnCheckedValue = e.target.checked;
    if (addOnCheckedValue === true) {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.addOnItems.findIndex(
          (item) => item.id === addonObject.id
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].addOnItems[itemIndex].checked = true
        };
      });

      modifiedItemsTotal.map((data) => {
        data.addOnItems.map((value) => {
          if (addonObject.id === value.id) {
            lineAddonsData.push(addonObject);
            const desc = addonObject.name;
            modifiersForChecBox.push({
              name: `${desc}`,
              id: addonObject.id,
              quantity: 1,
            });
            const index = lineAddonsData.findIndex(
              (data) => data.id === cartArrayId
            );
            product.listprice = (
              parseFloat(product.listprice) +
              parseFloat(lineAddonsData[index].price)
            ).toFixed(2);
          }
          return true
        });
        return true
      });
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.addOnItems.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });

      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        lineAddonsData: lineAddonsData,
        modifiedItemsTotal: modifiedItemsTotal

      });
    }
    else {
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.addOnItems.findIndex(
          (item) => item.id === addonObject.id
        );
        if (itemIndex > -1)
          delete modifiedItemsTotal[mi].addOnItems[itemIndex].checked;
      });

      const index = lineAddonsData.findIndex(
        (data) => data.id === cartArrayId
      );
      const index1 = modifiersForChecBox.findIndex(
        (data) => data.id === cartArrayId
      );
      if (index >= 0 || index1 >= 0) {

        product.listprice = (
          parseFloat(product.listprice) -
          parseFloat(lineAddonsData[index].price)
        ).toFixed(2);
        modifiersForChecBox.splice(index, 1);
        lineAddonsData.splice(index, 1);

      }
      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.addOnItems.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) modifiedItemsTotal[mi].selected = true;
      });

      modifiedItemsTotal.forEach((modifier, mi) => {
        const itemIndex = modifier.addOnItems.findIndex((item) =>
          item.hasOwnProperty("checked")
        );
        if (itemIndex > -1) {
          modifiedItemsTotal[mi].selected = true;
        } else {
          modifiedItemsTotal[mi].selected = false;
        }
      });
      this.setState({
        product: product,
        modifiersForChecBox: modifiersForChecBox,
        lineAddonsData: lineAddonsData,
        modifiedItemsTotal: modifiedItemsTotal
      });
    }
  };

  onAddItem = () => {
    const { history } = this.props;
    const {
      product,
      lineAddonsData,
    } = this.state;
    const basket = JSON.parse(localStorage.getItem("basket"));
    const selectedPId = uuidv4()
    product.Quantity = 1;
    product.selectedAddons = lineAddonsData
    product.selectedPId = selectedPId

    basket.push(product);

    let total = 0.0;
    let itemsQuantity = 0.0;

    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listprice).toFixed(2) * basket[i].Quantity;
        itemsQuantity += basket[i].Quantity;

      } else {
        total += parseFloat(basket[i].listprice).toFixed(2) * basket[i].Quantity;
        itemsQuantity += basket[i].Quantity;

      }
    }
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("itemsQuantity", itemsQuantity);

    history.push("../addOnCart/index.js");
  }

  onCartClick = () => {
    const { history } = this.props;
    history.push("../insideCart/index.js");
  }


  render() {
    const { product, productPrice, darkTheme, modifiedItemsTotal, loading } = this.state
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveTextTime = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };


    const responsiveDesignForStatus = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const intFrameHeight = window.innerHeight;
    const antIcon = <LoadingOutlined spin />;
    const Quantity = JSON.parse(localStorage.getItem("itemsQuantity"))

    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "40vh",
          color: "#BD1906",
        }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,

          }}
        >
          <Row>
            <Col {...responsiveDesignForColumn}>
              <img style={{ borderRadius: "0px", width: "100%", height: "100%" }}
                alt="search"
                src={product.imageUrl} />
            </Col>
            <Row style={{ padding: "15px", position: "absolute", width: "100%" }}>
              <Col {...responsiveTextTime}>
                <button
                  style={{ borderRadius: "100px", border: "0px solid black", height: "25px", backgroundColor: "#fff" }}
                >
                  <img
                    src={LeftArrow}
                    alt="Status"
                    onClick={this.onProductsArrow}
                  />
                </button>
              </Col>
              <Col {...responsiveText} style={{ textAlign: "right" }}>
                <button
                  style={{ borderRadius: "100px", border: "0px solid black", marginRight: "10px", height: "25px", backgroundColor: "#fff" }}
                > <img
                    style={{ textAlign: "left" }}
                    alt="search"
                    src={AddonSearch}
                  /></button>
                <button
                  style={{ borderRadius: "100px", border: "0px solid black", marginRight: "10px", height: "25px", backgroundColor: "#fff" }}
                > <img
                    style={{ textAlign: "left" }}
                    alt="search"
                    src={Heart}
                  /></button>
                <button
                  style={{ borderRadius: "100px", border: "0px solid black", height: "25px", backgroundColor: "#fff" }}
                > <img
                    style={{ textAlign: "left" }}
                    alt="search"
                    src={AddonCart}
                    onClick={this.onCartClick}

                  /><button className="addNotifications" width="20px">
                    {Quantity}
                  </button></button>
              </Col>
            </Row>
          </Row>
          <div
            style={{
              backgroundColor: `${darkTheme === true ? "#2114148F" : "#2114148F"}`,
              borderRadius: "0px",
              bottom: 0,
              position: "absolute",
              width: "100%",
              height: "75%",
              opacity: "99%",
              zIndex: "500",
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col
                {...responsiveText}
                style={{
                  padding: "10px",
                  paddingBottom: "0px",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    color: `${darkTheme === true ? "white" : "white"}`,
                    fontSize: "14px",
                    fontWeight: "550",
                    marginBottom: "5px",
                  }}
                >
                  {product.name}              </p>
              </Col>
              <Col
                {...responsiveTextTime}
                style={{
                  padding: "10px",
                  textAlign: "right",
                  paddingBottom: "0px",
                }}
              >
                <img
                  style={{ textAlign: "center", }}
                  alt="group"
                  src={Group8809}
                />
              </Col>
            </Row>
            <Row style={{ textAlign: "center", paddingTop: "5px" }}>
              <Col
                {...responsiveText}
                style={{ padding: "10px", textAlign: "left", paddingTop: "0px" }}
              >
                <p
                  style={{
                    color: `${darkTheme === true ? "white" : "white"}`,
                    marginBottom: "5px",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  ₹{productPrice}
                </p>
              </Col>
              <Col
                {...responsiveTextTime}
                style={{
                  padding: "10px",
                  textAlign: "right",
                  paddingTop: "10px",
                  marginTop: "-12px",
                }}
              >
                <Button className="addRatingButton">
                  {" "}
                  <span
                    style={{
                      color: `${darkTheme === true ? "white" : "#000000"}`,
                      marginBottom: "0px",
                      fontSize: "14px",
                      fontWeight: "500",

                    }}
                  >
                    4.5
                </span>&nbsp;
                  <img
                    style={{ textAlign: "right", width: "18px", marginTop: "-4px", paddingLeft: "5px" }}
                    src={Favorites}
                    alt="Favorites"
                  />
                </Button>
              </Col>
            </Row>
            <div
              style={{
                backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                borderRadius: "0px",
                bottom: 0,
                position: "absolute",
                width: "100%",
                height: "82%",
                zIndex: "1000",
              }}
            >
              <Row
                {...responsiveDesignForColumn}
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  marginLeft: "0px",
                  paddingTop: "20px"
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <div
                    style={{
                      maxHeight: "380px",
                      overflowY: "scroll",
                      paddingBottom: "25px",
                    }}
                  >
                    <Form style={{ paddingBottom: "0px" }}>
                      {product.add_on_group ? product.add_on_group.map(data => {
                        return (
                          <div
                            style={{
                              backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                              borderRadius: "0px",
                              padding: "8px",
                              color: `${darkTheme === true ? "white" : "#000000"}`,
                              marginBottom: "0px",
                            }}
                          >
                            {" "}
                            <Col {...responsiveDesignForItemData}>
                              <b style={{ fontSize: "16px" }}>
                                {data.name}
                              </b>
                            </Col>

                            <FormItem style={{ paddingTop: "5px" }}>

                              {data.addOnItems ? data.addOnItems.map(value => {
                                return (
                                  <Col {...responsiveDesignForColumn}>
                                    <Row>
                                      <Col {...responsiveDesignForItemData}>
                                        <Checkbox
                                          value={value}
                                          style={{
                                            fontSize: "14px",
                                            color: `${darkTheme === true ? "white" : "#000000"}`,
                                            paddingBottom: "8px",
                                          }}
                                          onChange={this.onChange}
                                        >
                                          {value.name}
                                        </Checkbox>
                                      </Col>
                                      <Col
                                        {...responsiveDesignForStatus} style={{ textAlign: "right" }}>                                    <span >+ ₹{value.price}</span>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              }) : null}

                            </FormItem>
                          </div>
                        )
                      }) : null}
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row style={{ margin: "0px", bottom: 0, position: "absolute", width: "100%" }}>
                <Col {...responsiveDesignForColumn}>
                  <Card
                    style={{
                      border: "0px",
                      backgroundColor: "#F2F2F2",
                    }}
                  >
                    <Row style={{ textAlign: "left" }}>
                      <Col
                        {...responsiveText}
                        style={{
                          paddingBottom: "0px",
                          textAlign: "left",
                        }}
                      >
                        <p style={{
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "500",

                        }}> {modifiedItemsTotal ? modifiedItemsTotal.map(data => {
                          return data.selected === true ? data.addOnItems.map(value => {
                            return value.checked ? (
                              value.name + ","
                            ) : null
                          }) : null
                        }) : null}</p>

                      </Col>
                      <Col
                        {...responsiveTextTime}
                        style={{

                          paddingBottom: "0px",
                          textAlign: "left",
                        }}
                      ></Col>
                    </Row>


                    <Row style={{ textAlign: "left" }}>
                      <Col
                        {...responsiveText}
                        style={{
                          paddingTop: "5px",
                          textAlign: "left",
                        }}
                      >
                        <p
                          style={{
                            color: `${darkTheme === true ? "#fff" : "#000000"}`,
                            fontSize: "14px",
                            marginBottom: "0px",
                            fontWeight: "600",
                          }}
                        >
                          Item Total ₹{product.listprice}
                        </p>
                      </Col>
                      <Col
                        {...responsiveTextTime}
                        style={{
                          textAlign: "right",
                        }}
                      > <Button style={{
                        backgroundColor: "#D82215",
                        borderRadius: "4px",
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "600",
                        border: "none"

                      }} onClick={() => {
                        this.onAddItem();
                      }}>ADD ITEM</Button></Col>
                    </Row>

                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default AdditionalCart;
