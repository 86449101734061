import React from "react";
import axios from "axios";
import { Row, Col, Spin, Card, Button, Image } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { fnbURL } from "./config";
import StatusGroup from "./../images/Group.svg";
import StatusBasket from "./../images/basket.svg";
import Foodtray from "./../images/foodtray.svg";
import Rectangle from "./../images/Rectangle.png";
import orderStatus4 from "./../images/orderStatus.svg";
import Heart from "./../images/heart.svg";

import "./thanks.css";
import "./viewCart/addOnCart/index.css";

class Thanks extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();

    this.state = {
      subCategoryItems: [],
      allProductsAtt: [],
      comparingProducts: [],
      storeId: "",
      Category_Id: 0,
      subCategory_Id: "",
      itemsValue: 0.0,
      product: {},
      basket: [],
      categoryItems: [],
      total: 0.0,
      allProducts: [],
      loading: true,
      Category_IdFor: 0,
      buttonFlag: false,
      scrollPosition: 300,
      darkTheme: false,
      storeInfo: [],
      allMenus: [],
      isaddOn: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    const getStore = {
      query: `query {
        getStores(configId: "2EF21B71E41C4730B6046409F979CC17") {
            name
            promotionBanner
            announcement
            darkTheme
            storeConfig {
                name
                shortDescription
                storeImage
                storeId
                csBunit {
                    csBunitId
                    value
                    name
                }
                storeTimings {
                    startTime
                    endTime
                    isMonday
                    isTuesday
                    isWednesday
                    isThursday
                    isFriday
                    isSaturday
                    isSunday
                }
                storeHolidays {
                    name
                    holidayDate
                }
            }
        }
    }`,
    };
    axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getStore,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      const storeInfo = response.data.data.getStores;
      localStorage.setItem("storeInfo", JSON.stringify(storeInfo));
      if (storeInfo !== null) {
        let restaurants = storeInfo.storeConfig;
        this.setState({ loading: false, storeInfo: restaurants });
      }
    });
  };

  onRestaurant = (key) => {
    const { storeInfo } = this.state;
    storeInfo.map((data) => {
      if (key === data.storeId) {
        let restaurantId = data.storeId;
        this.getProducts(restaurantId);
      }
      return true;
    });
  };

  getProducts = (restaurantId) => {
    this.setState({ loading: true });
    const { history } = this.props;
    const getProduct = {
      query: `query {
        getProducts(commerceConfigId: "${restaurantId}") {
          product_id
          category_id
          group_id
          location_id
          sku
          name
          short_desc
          veg
          unitprice
          listprice
          imageUrl
          bestseller
          available
          available_from
          available_to
          next_available_from
          next_available_to
          add_on_group {
            id
            type
            name
            minqty
            maxqty
            addOnItems {
              id
              name
              price
            }
          }
          attributeGroup {
            id
            name
            attribute {
              id
              value
              name
            }
          }
          ingredients
        }
      }`,
    };
    axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getProduct,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      if (response.data.data.getProducts) {
        const allProducts = response.data.data.getProducts;
        localStorage.setItem("restaurantId", restaurantId);
        localStorage.setItem("allProducts", JSON.stringify(allProducts));
        history.push("./viewCart/addOnCart/index.js");
        this.setState({ loading: false });
      }
    });
  };

  onViewCartClick = () => {
    const { history } = this.props;
    history.push("./viewCart/insideCart/index.js");
  };

  onAddOns = () => {
    localStorage.setItem("showButtonFlag", JSON.stringify(true));
    const { history } = this.props;
    history.push("./viewCart/addOnCart/index.js");
  };

  onAddOnCart = () => {
    const { history } = this.props;
    history.push("./viewCart/insideCart/index.js");
    this.setState({ flagFor: true });
  };

  onAddPlus = () => {
    const { history } = this.props;
    history.push("./viewCart/additionalCart/index.js");
    this.setState({ flagFor: true });
  };

  onAddProduct = () => {
    const { history } = this.props;
    history.push("./viewCart/addOnCart/index.js");
  };

  onClickOfCategory = (key) => {
    const { categoryItems } = this.state;
    localStorage.setItem("Category_Id", key);
    let allProductsDataSub = [];
    categoryItems.map((data) => {
      if (key === data.parentProductCategory) {
        allProductsDataSub = data;
      }
      return true;
    });
    this.setState({
      subCategoryItems: allProductsDataSub,
      Category_Id: key,
      Category_IdFor: 0,
    });
  };

  render() {
    const { basket, loading, darkTheme, storeInfo } = this.state;

    let itemsQuantity = 0.0;
    for (let i = 0; i < basket.length; i += 1) {
      itemsQuantity += basket[i].Quantity;
    }
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveTextTime = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignForStatus = {
      xxl: 2,
      xl: 3,
      lg: 3,
      xs: 6,
      sm: 4,
      md: 4,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const antIcon = <LoadingOutlined spin />;
    const intFrameHeight = window.innerHeight;

    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "40vh",
          color: "#BD1906",
        }}
        size="large"
      >
        <div
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            width: "100%",
            paddingTop: "8px",
            maxHeight: `${intFrameHeight}px`,
            overflowY: "scroll",
          }}
        >
          <Row>
            <Col {...responsiveDesignForColumn}>
              <p
                style={{
                  color: `${darkTheme === true ? "white" : "#000000"}`,
                  fontSize: "16px",
                  paddingTop: "0px",
                  paddingLeft: "10px",
                  fontWeight: 600,
                }}
              >
                Order Status
              </p>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "-10px",
            }}
          >
            <br />
            <Col {...responsiveDesignForColumn}>
              <Card
                style={{
                  backgroundColor: "#FFCC00",
                  borderRadius: "5px",
                  border: "0px",
                  cursor: "pointer",
                  height: "100px",
                  padding: "12px",
                }}
                onClick={this.onDineInClick}
              >
                <Row>
                  <Col
                    {...responsiveDesignForStatus}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      position: "absolute",
                      width: "50px",
                      height: "50px",
                      paddingLeft: "16px",
                      paddingTop: "11px",
                      left: "8%",
                    }}
                  >
                    <img
                      src={StatusBasket}
                      alt="Status"
                      style={{ borderRadius: "0px" }}
                    />
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "8px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        marginLeft: "-33px",
                        paddingTop: "22px",
                      }}
                    >
                      Ordered At 1:20 PM
                    </p>
                  </Col>

                  <Col
                    {...responsiveDesignForStatus}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      position: "absolute",
                      width: "50px",
                      height: "50px",
                      padding: "12px",
                      left: "32%",
                    }}
                  >
                    <img
                      src={StatusGroup}
                      alt="Status"
                      style={{ borderRadius: "0px" }}
                    />
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "7px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        marginLeft: "-25px",
                        paddingTop: "19px",
                      }}
                    >
                      Food Is Being Prepared
                    </p>
                  </Col>

                  <Col
                    {...responsiveDesignForStatus}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      position: "absolute",
                      width: "50px",
                      height: "50px",
                      paddingLeft: "15px",
                      paddingTop: "11px",
                      left: "56%",
                    }}
                  >
                    <img
                      src={Foodtray}
                      alt="Status"
                      style={{ borderRadius: "0px" }}
                    />
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "8px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        marginLeft: "-25px",
                        paddingTop: "22px",
                      }}
                    >
                      Ready To Collect
                    </p>
                  </Col>
                  <Col
                    {...responsiveDesignForStatus}
                    style={{
                      backgroundColor: "#00000040",
                      borderRadius: "50%",
                      position: "absolute",
                      width: "50px",
                      height: "50px",
                      padding: "12px",
                      left: "80%",
                      opacity: "60%",
                    }}
                  >
                    <img
                      src={orderStatus4}
                      alt="Status"
                      style={{ borderRadius: "0px", padding: "5px" }}
                    />
                    <p
                      style={{
                        color: "#000000",
                        fontSize: "8px",
                        fontWeight: 500,
                        whiteSpace: "nowrap",
                        marginLeft: "-20px",
                        paddingTop: "20px",
                      }}
                    >
                      Food Collected
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div style={{ padding: "10px", position: "relative" }}>
            <img
              src={Rectangle}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
            />
          </div>
          <Row>
            <Col {...responsiveDesignForColumn}>
              <p
                style={{
                  color: `${darkTheme === true ? "#fff" : "#000000"}`,
                  fontSize: "20px",
                  paddingTop: "0px",
                  paddingLeft: "10px",
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                Restaurants
              </p>
            </Col>
          </Row>
          {storeInfo.map((data) => {
            return (
              <div
                style={{
                  padding: "10px",
                  position: "relative",
                  backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                }}
                onClick={() => this.onRestaurant(data.storeId)}
              >
                <img
                  src={data.storeImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                />
                <Button
                  className="favouriteButton"
                  type="primary"
                  shape="circle"
                  icon={
                    <Image
                      style={{ textAlign: "left" }}
                      width={40}
                      src={Heart}
                    />
                  }
                />

                <Card
                  style={{
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    boxShadow: `${
                      darkTheme === true ? "none" : "0px 2px 1px 0.5px #f5f5f5"
                    }`,
                    border: "none",
                  }}
                >
                  <Row style={{ marginBottom: "-22px" }}>
                    <Col
                      {...responsiveDesignForItemData}
                      style={{ marginBottom: "-7px" }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontSize: "14px",
                          paddingTop: "3px",
                        }}
                      >
                        {data.csBunit.name}
                      </p>
                    </Col>
                    <Col
                      {...responsiveDesignForImage}
                      style={{ textAlign: "end" }}
                    >
                      <Button className="addbtn">Open</Button>
                    </Col>

                    <Col {...responsiveText}>
                      <p
                        style={{
                          color: "#8D8D8D",
                          fontSize: "10px",
                        }}
                      >
                        Pizza, Fast Food, Desserts
                      </p>
                    </Col>
                    <Col {...responsiveTextTime} style={{ textAlign: "end" }}>
                      <p
                        style={{
                          color: "#8D8D8D",
                          fontSize: "10px",
                        }}
                      >
                        Closed at 11:00 PM
                      </p>
                    </Col>
                  </Row>
                </Card>
              </div>
            );
          })}
        </div>
      </Spin>
    );
  }
}

export default Thanks;
