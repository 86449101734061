import React from "react";
import { Row, Col, Spin, Form, Input, Button, message } from "antd";
import axios from "axios";
import { fnbURL } from "./config";
import { LoadingOutlined } from "@ant-design/icons";
import Background from "./../images/BackgroundImage.svg";
import LeftArrow from "./../images/arrow-left.svg";
import Edit from "./../images/Icon feather-edit.svg";

import "antd/dist/antd.css";
import "./viewCart/addOnCart/index.css";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      userInfo: {},
      loading: false,
      darkTheme: false,
      edit: false,
    };
  }

  componentDidMount = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ userInfo: userInfo });
  };

  onSubmit = (values) => {
    console.log("values============>>>>>>>>>>>>", values);
    const number = JSON.parse(localStorage.getItem("phoneNumber"));
    const token = JSON.parse(localStorage.getItem("clientToken"));
    const getCustomer = {
      query: `mutation {
        upsertCustomer(customer: {
            b2cCustomerId: null,
            firstname: "${values.firstName}",
            lastname: "${values.lastName}",
            mobile_no: "${number}",
            email: "${values.email}"
        }) {
            status
            message
            b2cCustomerId
        }
    }`,
    };
    axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getCustomer,
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      // console.log("response update============>>>>>>>>>>>", response);
      const customer = response.data.data.upsertCustomer;
      if (customer.status === "200" && customer !== null) {
        localStorage.setItem("userInfo", JSON.stringify(values));
        const { history } = this.props;
        history.push("../../thanks");
      } else {
        message.error(customer.message);
      }
    });
  };

  onSignUpArrow = () => {
    const { history } = this.props;
    history.push("/thanks");
  };

  onEdit = () => {
    this.setState({ edit: true });
  };

  render() {
    const { loading, darkTheme, edit } = this.state;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const antIcon = <LoadingOutlined spin />;
    const intFrameHeight = window.innerHeight;

    return (
      <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "40vh",
          color: "#BD1906",
        }}
        size="large"
      >
        <div
          style={{
            margin: "0px",
            height: `${intFrameHeight}px`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundColor: `${darkTheme === true ? "black" : "white"}`,
            backgroundImage: `url("${Background}")`,
          }}
        >
          <Row
            style={{
              padding: "25px",
            }}
          >
            <Col {...responsiveDesignForImage}>
              <img
                src={LeftArrow}
                alt="Status"
                style={{ borderRadius: "0px", width: "20px" }}
                onClick={this.onSignUpArrow}
              />
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: "25px",
            }}
          >
            <Col {...responsiveDesignForItemData}>
              <p
                style={{
                  color: "#292929",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "5px",
                }}
              >
                Account Details
              </p>
            </Col>
            <Col {...responsiveDesignForImage} style={{ textAlign: "center" }}>
              <img
                src={Edit}
                alt="Status"
                style={{ borderRadius: "0px", width: "20px" }}
                onClick={this.onEdit}
              />{" "}
            </Col>
          </Row>
          <Row>
            <Col {...responsiveDesignForColumn} style={{ padding: "25px" }}>
              <Form
                name="control-ref"
                onFinish={this.onSubmit}
                ref={this.formRef}

                // onValuesChange={this.onFormLayoutChange}
              >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name",
                    },
                  ]}
                  style={{ color: "#FFFFFF", width: "100%" }}
                  initialValue={userInfo.firstName}
                >
                  {edit === false ? <Input disabled /> : <Input />}
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name",
                    },
                  ]}
                  style={{ color: "#FFFFFF", width: "100%" }}
                  initialValue={userInfo.lastName}
                >
                  {edit === false ? <Input disabled /> : <Input />}
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  initialValue={userInfo.email}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid Email",
                    },
                    {
                      required: true,
                      message: "Please input your Email",
                    },
                  ]}
                  style={{ color: "#FFFFFF", width: "100%" }}
                >
                  {edit === false ? <Input disabled /> : <Input />}
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col
                      {...responsiveDesignForColumn}
                      style={{ padding: "40px" }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#BD1906",
                          borderRadius: "8px",
                          textAlign: "center",
                          color: "#FFFFFF",
                          fontSize: "16px",
                          fontWeight: "600",
                          border: "none",
                          width: "100%",
                          height: "50px",
                        }}
                        htmlType="submit"
                      >
                        SUBMIT
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default Account;
