import React from "react";
import { Row, Col, Card, Checkbox, Radio } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

import "./index.css";
class DrinksModifier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {},
      onDrinkModifier: [],
      cartArrayId: "",
      totaPrice: 0.0,
      productPrice: 0.0,
      addOns: [],
      selectedComboDrink: {},
      subProductArray: [],
      modifiedItemsTotal: [],
    };
  }

  componentDidMount = () => {
    const product = JSON.parse(localStorage.getItem("productForDrinkModifier"));
    let modifiedItemsTotal = JSON.parse(
      localStorage.getItem("modifiedItemsTotal")
    );

    const onDrinkModifier = JSON.parse(
      localStorage.getItem("selectedDRinkModifier")
    );

    const selectedComboDrink = JSON.parse(
      localStorage.getItem("selectedComboDrink")
    );
    this.setState({
      product: product,
      onDrinkModifier: onDrinkModifier,
      productPrice: product.listPrice,
      selectedComboDrink: selectedComboDrink,
      modifiedItemsTotal: modifiedItemsTotal,
    });
  };

  onClickOfAddons = () => {
    const { history } = this.props;
    history.push("../addOnCart/index.js");
  };

  onBackToItems = () => {
    const { history } = this.props;
    history.push("../comboItems/index.js");
  };

  onRadio = (e) => {
    const { product, onDrinkModifier, modifiedItemsTotal } = this.state;

    const cartArrayId = e.target.value;

    let addOns = [];
    let addOnsObject = {};
    let Modifiers = [];

    onDrinkModifier.map((data) => {
      addOns = data.att_val_info;
      return true

    });

    addOns.map((value) => {
      const desc = JSON.parse(value.att_val_name).cn;

      if (cartArrayId === value.att_val_id) {
        onDrinkModifier.forEach((modifier, mi) => {
          const itemIndex = modifier.att_val_info.findIndex(
            (item) => item.att_val_id === value.att_val_id
          );

          if (itemIndex > -1)
            onDrinkModifier[mi].att_val_info[itemIndex].checked = true;
        });
        onDrinkModifier.forEach((modifier, mi) => {
          const itemIndex = modifier.att_val_info.findIndex((item) =>
            item.hasOwnProperty("checked")
          );
          if (itemIndex > -1) {
            onDrinkModifier[mi].selected = true;
          } else {
            onDrinkModifier[mi].selected = false;
          }
        });
        addOnsObject = value;
        Modifiers.push({
          description: `${desc}`,
          prodnum: value.att_val_sn,
          quantity: 1,
        });
      }
      return true

    });
    onDrinkModifier.map((data) => {
      modifiedItemsTotal.push(data);
      return true

    });
    const index = addOns.findIndex((data) => data.att_val_id === cartArrayId);
    product.listPrice = (
      parseFloat(product.listPrice) + parseFloat(addOns[index].price)
    ).toFixed(2);
    const listPrice = product.listPrice;

    this.setState({
      cartArrayId: cartArrayId,
      totaPrice: listPrice,
      addOns: Modifiers,
      addOnsObject: addOnsObject,
      modifiedItemsTotal: modifiedItemsTotal,
    });
  };

  onAddOnCart = () => {
    const { history } = this.props;
    const {
      product,
      addOns,
      selectedComboDrink,
      subProductArray,
      addOnsObject,
    } = this.state;
    const basket = JSON.parse(localStorage.getItem("basket"));
    const firstSubProductObject = {
      Modifiers: product.Modifiers,
      prodNum: `${product.Prod_IDFor}`,
    };

    const subProductObject = {
      Modifiers: addOns,
      prodNum: `${selectedComboDrink.product_sn}`,
    };

    subProductArray.push(firstSubProductObject);
    subProductArray.push(subProductObject);
    product.SubProduct = subProductArray;
    if (addOnsObject !== undefined) {
      product.selectedAddons.push(addOnsObject);
    }

    basket.push(product);

    let total = 0.0;

    for (let i = 0; i < basket.length; i += 1) {
      if (basket[i].listPrice) {
        total +=
          parseFloat(basket[i].listPrice).toFixed(2) * basket[i].Quantity;
      } else {
        total += parseFloat(basket[i].Price).toFixed(2) * basket[i].Quantity;
      }
    }
    localStorage.setItem("total", parseFloat(total).toFixed(2));
    localStorage.setItem("basket", JSON.stringify(basket));
    localStorage.setItem("modifiedItemsTotal", JSON.stringify([]));

    history.push("../../thanks");
  };

  render() {
    const {
      product,
      onDrinkModifier,
      cartArrayId,
      totaPrice,
      productPrice,
      modifiedItemsTotal,
    } = this.state;

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };

    const intViewportWidth = window.innerWidth;
    const intFrameHeight = window.innerHeight;
    return (
      <div
        style={{
          margin: "0px",
          height: `${intFrameHeight}px`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",

          backgroundColor: "#fff",
        }}
      >
        <Row>
          <Col {...responsiveDesignForColumn} style={{ marginBottom: "-70px" }}>
            <LeftOutlined
              onClick={this.onBackToItems}
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "black",
                marginTop: "15px",
                paddingLeft: "10px",
                position: "absolute",
              }}
              size="large"
              fontWeight="bold"
            />

            <img
              src={product.Image_Url}
              alt="product"
              width="100%"
              height="53%"
              style={{ borderRadius: "0px" }}
            />

            <Card style={{ backgroundColor: "#EFEFEF", borderRadius: "0px" }}>
              <Row style={{ margin: "0px" }}>
                <Col {...responsiveDesignForItemData}>
                  <b style={{ fontSize: "3.8vw" }}>{product.Name} </b>
                </Col>
                <Col {...responsiveDesignForImage}>
                  <p
                    style={{
                      color: "black",
                      fontSize: "20px",
                      paddingLeft: "4px",
                    }}
                  >
                    ${totaPrice === 0.0 ? productPrice : totaPrice}
                  </p>
                </Col>
              </Row>
              <Row>
                <div style={{ maxHeight: "55px", overflowY: "scroll" }}>
                  {modifiedItemsTotal.map((values) => {
                    if (values.selected) {
                      return (
                        <div>
                          {values.selected === true ? (
                            <div>
                              <Col {...responsiveDesignForColumn}>
                                <b style={{ fontSize: "3.3vw" }}>
                                  {" "}
                                  {JSON.parse(values.att_name).en}{" "}
                                </b>
                              </Col>

                              {values.att_val_info ? (
                                values.att_val_info.map((data) => {
                                  if (data.checked) {
                                    return (
                                      <div>
                                        {data.checked === true ? (
                                          <ul>
                                            <li>
                                              {" "}
                                              {JSON.parse(data.att_val_name).cn}
                                              &nbsp;{" "}
                                            </li>
                                          </ul>
                                        ) : (
                                          <ul style={{ display: "none" }}>
                                            <li>
                                              {" "}
                                              {JSON.parse(data.att_val_name).cn}
                                              &nbsp;{" "}
                                            </li>
                                          </ul>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <ul style={{ display: "none" }}>
                                        <li> </li>
                                      </ul>
                                    );
                                  }
                                })
                              ) : (
                                <Col style={{ display: "none" }}></Col>
                              )}
                            </div>
                          ) : (
                            <Col
                              {...responsiveDesignForColumn}
                              style={{ display: "none" }}
                            >
                              <b style={{ fontSize: "3.3vw" }}></b>
                            </Col>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Col
                            {...responsiveDesignForColumn}
                            style={{ display: "none" }}
                          >
                            <b style={{ fontSize: "3.3vw" }}>
                              {JSON.parse(values.att_name).en}{" "}
                            </b>
                          </Col>
                        </div>
                      );
                    }
                  })}
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row
          {...responsiveDesignForColumn}
          style={{
            padding: "10px",
            marginLeft: "0px",
            backgroundColor: "#fff",
          }}
        >
          <Col {...responsiveDesignForColumn}>
            <div>
              <Form style={{ paddingBottom: "30px" }}>
                {onDrinkModifier.map((values) => {
                  return (
                    <FormItem
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      {" "}
                      <Col {...responsiveDesignForItemData}>
                        <b style={{ fontSize: "3.8vw" }}>
                          {JSON.parse(values.att_name).en}{" "}
                        </b>
                      </Col>
                    </FormItem>
                  );
                })}

                <FormItem>
                  {onDrinkModifier.map((values) => {
                    return (
                      <Col>
                        {values.att_val_info.map((data) => {
                          return (
                            <Col
                              {...responsiveDesignForColumn}
                              style={{ paddingBottom: "15px" }}
                            >
                              {values.max_num === 1 ? (
                                <Radio.Group
                                  name="radiogroup"
                                  className={
                                    cartArrayId === data.att_val_id
                                      ? "ant-radio ant-radio-checked"
                                      : "ant-radio "
                                  }
                                  onChange={this.onRadio}
                                  value={data}
                                >
                                  <Radio value={data.att_val_id}>
                                    {" "}
                                    {JSON.parse(data.att_val_name).cn}(+${" "}
                                    {data.price})
                                  </Radio>
                                </Radio.Group>
                              ) : (
                                <Checkbox
                                  value={data}
                                  style={{ fontSize: "16px" }}
                                  onChange={this.onChange}
                                >
                                  {JSON.parse(data.att_val_name).cn}(+${" "}
                                  {data.price})
                                </Checkbox>
                              )}
                            </Col>
                          );
                        })}
                      </Col>
                    );
                  })}
                </FormItem>
              </Form>
              <Row
                style={{
                  position: "fixed",
                  bottom: 0,
                  width: `${intViewportWidth}px`,
                  paddingBottom: "9px",
                  paddingRight: "20px",
                  textAlign: "center",
                }}
              >
                <Col {...responsiveDesignForColumn}>
                  <Card
                    style={{
                      backgroundColor: "#F35D5D",
                    }}
                    onClick={() => this.onAddOnCart()}
                  >
                    <Row>
                      <Col {...responsiveDesignForColumn}>
                        <b
                          style={{
                            color: "white",
                            fontWeight: "700",
                            fontSize: "20px",
                          }}
                        >
                          ADD TO CART
                        </b>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <br />
      </div>
    );
  }
}

export default DrinksModifier;
