import React from "react";
import { Link } from "react-router-dom";
import { Drawer, Menu, Col, Row } from "antd";
import { Navbar, Container } from "react-bootstrap";
import shoppingCart from "../../images/shopping-cart.svg";
import shoppingCartWhite from "../../images/shoppingCartWhite.svg";
import menuIcon from "../../images/menuIcon.svg";
import menuLightIcon from "../../images/menuLighticon.svg";
import LeftArrow from "../../images/arrow-left.svg";
import Home from "../../images/home-line.svg";
import List from "../../images/list-alt.svg";
import MenuHeart from "../../images/menuHeart.svg";
import Notification from "../../images/notifications-outline.svg";
import Wallet from "../../images/wallet-line.svg";
import Logout from "../../images/logout.svg";
import Account from "../../images/user.svg";
import User from "../../images/account-circle.svg";

import "./header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false,
      visible: false,
      placement: "left",
      storeName: "",
      streetName: "",
      landingInfo: {},
      darkTheme: false

    };
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }
  setIsNavExpanded = (isNavExpanded) => {
    this.setState({ isNavExpanded: isNavExpanded });
  };
  closeMenu = () => {
    this.setState({ isNavExpanded: false });
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      // If clicked outside menu, close the navbar.
      this.setState({ isNavExpanded: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };

  onHomePage = (e) => {
    window.location = "/#/thanks";
    this.setState({
      visible: false,
    })
  };
  onTerms = (e) => {
    window.location = "/#/viewCart/orderPlacing/index.js";
  };
  onRefund = (e) => {
    window.location = "/#/refundAndCancel";
  };
  onPrivacy = (e) => {
    window.location = "/#/privacy";
  };
  onContact = (e) => {
    window.location = "/#/contactPage";
  };

  onCartArrow = () => {
    this.setState({
      visible: false,
    })
  }

  onAccount = () => {
    window.location = "/#/account";

  }

  onLogout = () => {
    localStorage.removeItem("clientToken");
    window.location = "/#/";
  }

  render() {
    const { placement, visible, darkTheme } = this.state;

    const responsiveDesignForColumn = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };

    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const responsiveText = {
      xxl: 16,
      xl: 16,
      lg: 16,
      xs: 16,
      sm: 16,
      md: 16,
    };

    const responsiveDesignForImage = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };

    const responsiveDesignForIcon = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const Quantity = JSON.parse(localStorage.getItem("itemsQuantity"))
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const name = userInfo.firstName + " " + userInfo.lastName
    return (
      <div
        style={{ backgroundColor: "black" }}
        ref={(node) => (this.node = node)}
      >
        <Navbar
          onToggle={this.setIsNavExpanded}
          expanded={this.state.isNavExpanded}
          expand="lg"
          className="navbar-light osahan-nav shadow-sm"
          style={{ textAlign: "center", }}
        >
          <Container style={{ paddingLeft: "10px", paddingRight: "20px", backgroundColor: `${darkTheme === true ? "black" : "white"}` }}>
            <Col {...responsiveDesignForImage} style={{ textAlign: "left" }}>
              <img src={darkTheme === true ? menuIcon : menuLightIcon} alt="menuIcon" onClick={this.showDrawer} />
            </Col>
            <Col {...responsiveDesignForImage}>
              <Navbar.Brand
                to="/"
                style={{
                  fontWeight: "700",
                  width: "50%",
                  color: `${darkTheme === true ? "#fff" : "#000000"}`,
                  fontSize: "20px",
                }}
              >
                Spaces
              </Navbar.Brand>
            </Col>
            <Col {...responsiveDesignForImage} style={{ textAlign: "right" }}>
              <Link
                to="../viewCart/insideCart/index.js"
                style={{ textAlign: "right" }} shoppingCartWhite
              >
                <img
                  src={darkTheme === true ? shoppingCart : shoppingCartWhite}
                  alt="shoppingCart"
                  style={{
                    width: "25px",
                  }}
                  onClick={this.onCartClick}
                />
                <button className="notifications" width="20px">
                  {Quantity}
                </button>
              </Link>
            </Col>
            <Col {...responsiveDesignForColumn} style={{ textAlign: "left" }}>
              <p
                style={{
                  color: `${darkTheme === true ? "#fff" : "#000000"}`,
                  fontSize: "14px",
                  paddingTop: "0px",
                  fontWeight: 500,
                  marginBottom: "0px",
                }}
              >
                Raheja IT Space, Ground Floor Cafeteria
              </p>
            </Col>
          </Container>
        </Navbar>
        <Drawer
          placement={placement}
          closable={false}
          onClose={this.onClose}
          visible={visible}
          key={placement}
        >
          <div style={{ backgroundColor: "#f2f2f2", padding: "10px", margin: "-10px" }}>
            <Row
              style={{
                padding: "10px"
              }}
            >
              <Col
                {...responsiveDesignForImage}
              >
                <img
                  src={LeftArrow}
                  alt="Status"
                  style={{ borderRadius: "0px", width: "20px", paddingTop: "12px" }}
                  onClick={this.onCartArrow}
                />
              </Col>
              <Col
                {...responsiveText}
              >
                <p style={{ color: "#BD1906", fontSize: "25px", fontWeight: "600", marginBottom: "5px" }}>Spaces</p>
              </Col>
            </Row>
            <Row
              style={{
                padding: "10px"
              }}
            >
              <Col
                {...responsiveDesignForIcon}
              >
                <img
                  src={User}
                  alt="Status"
                  style={{ borderRadius: "0px", width: "25px" }}
                  onClick={this.onCartArrow}
                />
              </Col>
              <Col
                {...responsiveDesignForItemData}
              >
                <p style={{ color: "#000000", fontSize: "16px", fontWeight: "400", marginBottom: "5px" }}>{name}</p>
              </Col>
            </Row>
          </div>
          <Menu
            // defaultSelectedKeys={["1"]}
            // onClick={this.handleDashboardMenuClick}
            mode="inline"
            theme="light"
            inlineCollapsed={this.state.collapsed}
            style={{ marginTop: "10px" }}
          >
            <Menu.Item key="1" style={{ borderLeft: "3px solid red" }} onClick={this.onHomePage}>
              <img src={Home}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-6px" }}
              />&ensp;&ensp;&ensp;
              Home
            </Menu.Item>
            <Menu.Item key="2" onClick={this.onTerms}>
              <img src={List}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-5px" }}
              />&ensp;&ensp;&ensp;
              Order History
            </Menu.Item>
            <Menu.Item key="3" onClick={this.onRefund}>
              <img src={Wallet}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-5px" }}
              />&ensp;&ensp;&ensp;
              Wallet
            </Menu.Item>
            <Menu.Item key="4" onClick={this.onPrivacy}>
              <img src={Notification}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-5px" }}
              />&ensp;&ensp;&ensp;
              Notifications
            </Menu.Item>
            <Menu.Item key="5" onClick={this.onAccount}>
              <img src={Account}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-6px" }}
              />&ensp;&ensp;&ensp;
              Account
            </Menu.Item>
            <Menu.Item key="6" onClick={this.onContact}>
              <img src={MenuHeart}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-5px" }}
              />&ensp;&ensp;&ensp;
              Favorites
            </Menu.Item>
            <Menu.Item key="6" onClick={this.onLogout}>
              <img src={Logout}
                alt="Status"
                style={{ borderRadius: "0px", width: "15px", marginTop: "-5px" }}
              />&ensp;&ensp;&ensp;
              Logout
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default Header;
