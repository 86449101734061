import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Sample from "./components/Sample";
import Login from "./components/Login";

import Thanks from "./components/Thanks";
import TermsAndConditions from "./components/termsAndConditions";
import RefundAndCancel from "./components/refundAndCancel";
import Privacy from "./components/privacy";
import ContactPage from "./components/contactPage";
import InsideCart from "./components/viewCart/insideCart/index";
import Payment from "./components/viewCart/payment/index";
import PickUp from "./components/viewCart/pickUp/index";
import SalesType from "./components/viewCart/salesType/index";
import OrderPlacing from "./components/viewCart/orderPlacing/index";
import OrderPlacingAtCounter from "./components/viewCart/orderAtCounter/index";
import AddOnCart from "./components/viewCart/addOnCart/index";
import ComboItem from "./components/viewCart/comboItems/index";
import AdditionalCart from "./components/viewCart/additionalCart/index";
import DrinksModifier from "./components/viewCart/drinkModifier/index";
import Feedback from "./components/feedBack";
import ErrorInPlacing from "./components/viewCart/errorPage/index";
import SuccessQr from "./components/successQr"
import PrivateRoute from "./components/privateRoute"

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./App.css";
import Account from "./components/account";

class App extends React.Component {

  render() {
    const token = JSON.parse(localStorage.getItem("clientToken"))
    return (
      <>
        {this.props.location.pathname !== "/" &&
          this.props.location.pathname !== "/sample" &&
          this.props.location.pathname !== "/Login" &&
          this.props.location.pathname !== "/termsAndConditions" &&
          this.props.location.pathname !== "/refundAndCancel" &&
          this.props.location.pathname !== "/privacy" &&
          this.props.location.pathname !== "/contactPage" &&
          this.props.location.pathname !== "/viewCart/insideCart/index.js" &&
          this.props.location.pathname !== "/viewCart/payment/index.js" &&
          this.props.location.pathname !== "/viewCart/pickUp/index.js" &&
          this.props.location.pathname !== "/viewCart/salesType/index.js" &&
          this.props.location.pathname !== "/viewCart/orderPlacing/index.js" &&
          this.props.location.pathname !== "/viewCart/addOnCart/index.js" &&
          this.props.location.pathname !== "/viewCart/orderAtCounter/index.js" &&
          this.props.location.pathname !== "/viewCart/errorPage/index.js" &&
          this.props.location.pathname !== "/viewCart/comboItems/index.js" &&
          this.props.location.pathname !== "/viewCart/additionalCart/index.js" &&
          this.props.location.pathname !== "/feedBack" &&
          this.props.location.pathname !== "/account" &&
          this.props.location.pathname !== "/successQr" &&
          this.props.location.pathname !== "/viewCart/drinkModifier/index.js" ? (
          <div>
            {token && <Header />}
          </div>
        ) : (
          <Switch>
            <Route exact path="/" component={Sample} />
            <PrivateRoute path="/sample" exact component={Sample} />
            <PrivateRoute path="/Login" exact component={Login} />
            <PrivateRoute
              path="/termsAndConditions"
              exact
              component={TermsAndConditions}
            />
            <PrivateRoute path="/refundAndCancel" exact component={RefundAndCancel} />
            <PrivateRoute path="/privacy" exact component={Privacy} />
            <PrivateRoute path="/contactPage" exact component={ContactPage} />
            <PrivateRoute
              path="/viewCart/insideCart/index.js"
              exact
              component={InsideCart}
            />
            <PrivateRoute
              path="/viewCart/addOnCart/index.js"
              exact
              component={AddOnCart}
            />
            <PrivateRoute
              path="/viewCart/payment/index.js"
              exact
              component={Payment}
            />
            <PrivateRoute path="/viewCart/pickUp/index.js" exact component={PickUp} />
            <PrivateRoute
              path="/viewCart/salesType/index.js"
              exact
              component={SalesType}
            />
            <PrivateRoute
              path="/viewCart/orderPlacing/index.js"
              exact
              component={OrderPlacing}
            />
            <PrivateRoute
              path="/viewCart/orderAtCounter/index.js"
              exact
              component={OrderPlacingAtCounter}
            />

            <PrivateRoute
              path="/viewCart/errorPage/index.js"
              exact
              component={ErrorInPlacing}
            />
            <PrivateRoute
              path="/viewCart/comboItems/index.js"
              exact
              component={ComboItem}
            />
            <PrivateRoute
              path="/viewCart/additionalCart/index.js"
              exact
              component={AdditionalCart}
            />
            <PrivateRoute
              path="/viewCart/drinkModifier/index.js"
              exact
              component={DrinksModifier}
            />
            <PrivateRoute
              path="/feedBack"
              exact
              component={Feedback}
            />
            <PrivateRoute
              path="/account"
              exact
              component={Account}
            />
            <PrivateRoute
              path="/successQr"
              exact
              component={SuccessQr}
            />
          </Switch>
        )}
        <Switch>
          <PrivateRoute path="/thanks" exact component={Thanks} />
        </Switch>
      </>
    );
  }
}

export default App;
