import React from "react";
import { Row, Col, Card, Menu, Dropdown, message } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

import downArrow from "../../../images/downArrow.svg";
import { LeftOutlined } from "@ant-design/icons";

class PickUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickUpDate: "",
      pickUpTime: "",
      result: [],
    };
  }

  componentDidMount = () => {
    let pickUpTime = "";
    const work_time = JSON.parse(localStorage.getItem("pickUpTimings"));

    const result = [];
    function intervals(start, end) {
      var start1 = moment(start, "hh:mm ");
      var end1 = moment(end, "hh:mm ");

      var current = moment(start1);

      while (current <= end1) {
        result.push(current.format("HH:mm ").slice(0, 5));
        current.add(15, "minutes");
      }
    }
    let storeStartTime = "";
    let storeEndTime = "";

    work_time.real.map((data) => {
      var date = new Date();
      var toDay = date.getDay();
      if (toDay === data.id) {
        data.time.map((time) => {
          storeStartTime = time.start;
          storeEndTime = time.end;
          return true
        });
      }
      return true
    });

    intervals(`${storeStartTime}`, `${storeEndTime}`);
    // intervals("10:00", "23:00");
    var curDate = new Date();
    curDate = curDate.toDateString();
    var times = result.map((t) => {
      return new Date(curDate + " " + t);
    });
    var now = new Date();
    var min = Math.abs(now - times[0]);
    var result1 = "";
    for (let i = 1; i < times.length; i++) {
      if (Math.abs(now - times[i]) <= min) {
        min = Math.abs(now - times[i]);
        result1 = result[i];
      }
    }

    let a = result.indexOf(result1);
    let pickUpTimings = [];
    for (let i = a; i < result.length; ++i) {
      pickUpTimings.push(result[i]);
    }
    pickUpTimings = pickUpTimings.slice(1);

    pickUpTime = pickUpTimings[0];

    let today = new Date();

    today = today.toString();

    const getMonth = today.slice(4, 7);
    const getDate = today.slice(8, 10);
    const getYear = today.slice(11, 15);

    const pickUpDate = getDate + "-" + getMonth + "-" + getYear;

    this.setState({
      pickUpDate: pickUpDate,
      pickUpTime: pickUpTime,
      result: pickUpTimings,
    });
  };

  onTimeSelect = (e) => {
    const updateTime = e.item.props.value;
    this.setState({ pickUpTime: updateTime });
  };

  onPickUpConfirm = () => {
    const { pickUpTime } = this.state;
    const { history } = this.props;
    const date = new Date();
    const newDate = date.toISOString().slice(0, 11);
    if (pickUpTime !== "00:00") {
      const scheduleDate = newDate + pickUpTime;
      localStorage.setItem("scheduleDate", JSON.stringify(scheduleDate));
      history.push("../payment/index.js");
    } else {
      message.error("invalid time");
    }
  };

  onBackToMenu = () => {
    const { history } = this.props;

    history.push("../salesType/index.js");
  };

  render() {
    const { pickUpDate, pickUpTime, result } = this.state;
    const responsiveDesignForColumn = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 24,
      sm: 12,
      md: 12,
    };
    const responsiveDesignForImage = {
      xxl: 4,
      xl: 4,
      lg: 4,
      xs: 4,
      sm: 4,
      md: 4,
    };
    const responsiveDesignDineInAndtakeAway = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };
    const responsiveDesignForItemData = {
      xxl: 20,
      xl: 20,
      lg: 20,
      xs: 20,
      sm: 20,
      md: 20,
    };

    const menu = (
      <Menu>
        {result.map((data) => {
          return (
            <Menu.Item key="0" onClick={this.onTimeSelect} value={data}>
              {data}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const intFrameHeight = window.innerHeight;
    const intViewportWidth = window.innerWidth;
    return (
      <div
        style={{
          heigth: "100%",

          backgroundColor: "#fff",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          height: `${intFrameHeight}px`,
        }}
      >
        <Row>
          <Col span={24}>
            <Card
              style={{
                fontWeight: "regular",
                fontSize: "25px",
              }}
            >
              <Row
                style={{
                  margin: "0px",
                  paddingBottom: "3px",
                }}
              >
                <Col
                  {...responsiveDesignForImage}
                  style={{ paddingRight: "3px" }}
                >
                  <LeftOutlined
                    onClick={this.onBackToMenu}
                    style={{ fontSize: "20px" }}
                  />
                </Col>

                <Col
                  {...responsiveDesignForItemData}
                  style={{
                    textAlign: "center",
                    paddingRight: "35px",
                    paddingTop: "9px",
                    fontWeight: "700",
                    fontSize: "19px",
                  }}
                >
                  Please select pick-up time
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              paddingTop: "14px",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "16px", color: "#292929", opacity: "60%" }}>
              Pick-up-Date
            </p>
          </Col>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              paddingTop: "14px",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "16px", color: "#292929", opacity: "60%" }}>
              Pick-up-Time
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: "16px", color: "#292929", opacity: "100%" }}>
              {pickUpDate}
            </p>
          </Col>
          <Col
            {...responsiveDesignDineInAndtakeAway}
            style={{
              textAlign: "center",
            }}
          >
            <Row style={{ paddingLeft: "35%" }}>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                style={{ maxHeight: "150px", overflowY: "scroll" }}
              >
                <p
                  className="ant-dropdown-link"
                  style={{
                    fontSize: "16px",
                    color: "#292929",
                    opacity: "100%",
                    maxHeight: "150px",
                    overflowY: "scroll",
                  }}
                >
                  {pickUpTime}
                  <span>
                    <img src={downArrow} alt="img" />
                  </span>
                </p>
              </Dropdown>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            bottom: "0",
            width: `${intViewportWidth}px`,
          }}
        >
          <Col
            {...responsiveDesignForColumn}
            style={{
              padding: "7px",
            }}
          >
            <Card
              style={{
                backgroundColor: "#F35D5D",
                position: "relative",
              }}
              onClick={this.onPickUpConfirm}
            >
              <Row style={{ marginTop: "0px" }}>
                <Col
                  {...responsiveDesignForColumn}
                  style={{ textAlign: "center" }}
                >
                  <b style={{ color: "white", fontSize: "18px" }}>CONFIRM</b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PickUp;
