import React from "react";
import { Row, Col, Card, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import Background from "./../images/BackgroundImage.svg";
import LeftArrow from "./../images/arrow-left.svg";
import Star from "./../images/star.svg";

import "antd/dist/antd.css";
import "./viewCart/addOnCart/index.css";
import TextArea from "antd/lib/input/TextArea";

class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allProducts: [],
            darkTheme: false,
            restaurant: {},
            basket: []

        };
    }

    componentDidMount = () => {
        this.setState({ loading: true })
        const allProducts = JSON.parse(localStorage.getItem("allProducts"))
        const storeInfo = JSON.parse(localStorage.getItem("storeInfo"))
        const restaurantId = localStorage.getItem("restaurantId")
        const index = storeInfo.storeConfig.findIndex((data) => data.storeId === restaurantId);
        const restaurant = storeInfo.storeConfig[index];
        const basket = JSON.parse(localStorage.getItem("basket"))
        this.setState({ loading: false, allProducts: allProducts, restaurant: restaurant, basket: basket })

    };

    onBackToItems = () => {
        const { history } = this.props;
        history.push("../../thanks");
    };

    onCartArrow = () => {
        const { history } = this.props;
        history.push("../../thanks");
    }

    render() {
        const { loading,
            darkTheme, } = this.state
        const responsiveDesignForColumn = {
            xxl: 24,
            xl: 24,
            lg: 24,
            xs: 24,
            sm: 24,
            md: 24,
        };


        const responsiveText = {
            xxl: 16,
            xl: 16,
            lg: 16,
            xs: 16,
            sm: 16,
            md: 16,
        };

        const responsiveDesignForImage = {
            xxl: 4,
            xl: 4,
            lg: 4,
            xs: 4,
            sm: 4,
            md: 4,
        };
        const antIcon = <LoadingOutlined spin />;

        return (
            <Spin
                spinning={loading}
                tip="Loading..."
                indicator={antIcon}
                style={{
                    position: "relative",
                    top: "40vh",
                    color: "#BD1906",
                }}
                size="large"
            >
                <div
                    style={{
                        margin: "0px",
                        height: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top center",
                        backgroundColor: `${darkTheme === true ? "black" : "white"}`,
                        backgroundImage: `url("${Background}")`,
                    }}
                >
                    <Row
                        style={{
                            padding: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForImage}
                        >
                            <img
                                src={LeftArrow}
                                alt="Status"
                                style={{ borderRadius: "0px", width: "20px" }}
                                onClick={this.onCartArrow}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <p style={{ color: "#292929", fontSize: "22px", fontWeight: "600", marginBottom: "5px" }}>Give Feedback</p>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: "25px",
                            paddingTop: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <p style={{ color: "#292929", fontSize: "14", fontWeight: "400", marginBottom: "5px" }}>How was the food?</p>
                        </Col>
                        <Col
                            {...responsiveText}
                        >
                            <Row>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col> <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                            </Row>                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: "25px", paddingTop: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <p style={{ color: "#292929", fontSize: "14", fontWeight: "400", marginBottom: "5px" }}>How was the service provided?</p>
                        </Col>
                        <Col
                            {...responsiveText}
                        >
                            <Row>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col> <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                                <Col
                                    {...responsiveDesignForImage}
                                >
                                    <img
                                        src={Star}
                                        alt="Star"
                                        style={{ borderRadius: "0px" }}
                                        onClick={this.onCartArrow}
                                    />
                                </Col>
                            </Row>                        </Col>
                    </Row>
                    <Row
                        style={{
                            padding: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <p style={{ color: "#292929", fontSize: "14", fontWeight: "400", marginBottom: "5px" }}>Care to share more about the food</p>
                        </Col>
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <TextArea />                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: "25px",
                            paddingRight: "25px"
                        }}
                    >
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <p style={{ color: "#292929", fontSize: "14", fontWeight: "400", marginBottom: "5px" }}>Care to share more about the service</p>
                        </Col>
                        <Col
                            {...responsiveDesignForColumn}
                        >
                            <TextArea />                        </Col>
                    </Row>
                    <Row>
                        <Col {...responsiveDesignForColumn} style={{ padding: "45px" }}>
                            <Card
                                style={{
                                    backgroundColor: "#BD1906",
                                    borderRadius: "8px",
                                    textAlign: "center",
                                    color: "#FFFFFF",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    border: "none"

                                }}
                                onClick={() => {
                                    this.onViewCartClick();
                                }}
                            >
                                Submit Feedback
                </Card>
                        </Col>
                    </Row>
                </div>
            </Spin>
        );
    }
}

export default Feedback;
