
import React from "react";
import {Card,Row,Col,Button} from 'antd'
import {CheckCircleOutlined} from '@ant-design/icons'
import QRCode from "react-qr-code";
import moment from 'moment'

export class successQr extends React.Component {

  backTohome = () =>{
    const { history } = this.props;
    history.push('/thanks')
    // localStorage.setItem('basket',[])
    localStorage.setItem("basket", JSON.stringify([]));
    localStorage.setItem("total", 0);
    localStorage.setItem("itemsQuantity", 0);
    localStorage.removeItem("cwcOrderId")
  }
  render() {
    const responsiveDesignForImage = {
      xxl: 12,
      xl: 12,
      lg: 12,
      xs: 12,
      sm: 12,
      md: 12,
    };

    const responsiveDesignForImage1 = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    }
   const item = window.localStorage.getItem('totalProducts')
   const total = window.localStorage.getItem('total') 
   const newtotal = parseFloat(total) + 60
   const orderId =  window.localStorage.getItem('cwcOrderId')
   const orderdate1 = window.localStorage.getItem('orderDate')
   const onlyDate = moment(orderdate1).format('DD-MM-YYYY')
   const onlyTime = moment(orderdate1).format('HH:mm A')
   const QRvalue = `morder-${orderId}`
    return (
      <div>
         <Card style={{height:'100vh'}}>
           <Card style={{top:'10vh'}}>
            <div style={{textAlign:'center'}}>
              <CheckCircleOutlined style={{ fontSize: '4rem', color: '#D82215' }} />
              <p />
              <h4 style={{fontWeight:'bold'}}>Thank You!</h4>
              <p />
              <h4 style={{fontWeight:'bold'}}>{item} Items - ₹{newtotal}</h4>
            </div>
            <Row>
              <Col
              {...responsiveDesignForImage}
              >
              <div>Payment Option</div>
              </Col>
              <Col 
              style={{textAlign:'right'}}
              {...responsiveDesignForImage}
              >
              <div>Credit Card</div>
              </Col>
              <p />
              <Col
              {...responsiveDesignForImage}
              >
              <div>Date</div>
              </Col>
              <Col 
              style={{textAlign:'right'}}
              {...responsiveDesignForImage}
              >
              <div>{onlyDate} at {onlyTime}</div>
              </Col>
              <p />
              <Col
              {...responsiveDesignForImage}
              >
              <div>Shop</div>
              </Col>
              <Col 
              style={{textAlign:'right'}}
              {...responsiveDesignForImage}
              >
              <div>Western Wing Cafeteria</div>
              </Col>
            </Row>
            <Row>
            <Col
            style={{textAlign:'center'}}
            {...responsiveDesignForImage1}>
            <p />
                <p />
                <br />
            <div>Your payment confirmation</div>
            {/* <img src ="./img/qr-code.png" alt="qrcode" style={{height:'102px',width:'102px'}} /> */}
            <QRCode
              size={128}
              value={QRvalue}
              imageSettings={{
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }} />
            <div>Your receipt has been stored.</div>
            </Col>
            </Row>
           </Card>
           <div>
            <div>
              <Button onClick={this.backTohome} style={{ background: '#D82215', width: '80%', color: 'white', position: 'absolute', marginTop: '16vh',right:'2.4rem' }}>Back to Home</Button>
            </div>
          </div>
         </Card>
      </div>
    );
  };
};

export default successQr